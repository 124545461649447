export const isValidName = (name) => {
  // Regular expression to allow only letters and spaces
  const regex = /^[A-Za-z\s]*$/;
  return regex.test(name);
};

export const isValidPhoneNo = (phoneNo) => {
  const regex = /^\d*$/;
  return regex.test(phoneNo);
};

export const isValidNumber = (number) => {
  const regex = /^\d*$/;
  return regex.test(number);
};

export const isValidAlphanumeric = (value) => {
  const regex = /^[a-zA-Z0-9 ]*$/;
  return regex.test(value);
};
