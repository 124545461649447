import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

const PrivateRoute = ({ element, fallbackPath }) => {
  const isLoggedIn = !!localStorage.getItem("token");
  console.log("isLoggedIn", isLoggedIn);

  return (
    <Routes>
      <Route
        path="/"
        element={isLoggedIn ? element : <Navigate to={fallbackPath} replace />}
      />
    </Routes>
  );
};

export default PrivateRoute;
