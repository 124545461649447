import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

//send notification
const getnotify = (email) => {
  debugger;
  console.log("email: " + email);
  return http.post(`notification/testnotification/${email}`);
};
const getSettingData = (supervisorId) => {
  debugger;
  return http.get(`/user/get/${supervisorId}`);
};

// -----link social account
const linkSocialAccount = (data) => {
  debugger;
  return http.post(`/auth/link/account`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const changePassword = (data) => {
  return http.post(`/user/changepassword`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const SettingServices = {
  getnotify,
  getSettingData,
  linkSocialAccount,
  changePassword,
};

export default SettingServices;
