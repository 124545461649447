import React from "react";
import Footer from "./footer";
import Header from "./header";

const RefundPolicy = () => {
  return (
    <>
      <Header />
      <div class="container pt-3 pb-4">
        <h1>
          <strong style={{ fontSize: "30px" }}>Refund/Return Policy</strong>
        </h1>
        <p className="ms-2">
          Last Updated: <strong>May 12, 2024</strong>
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          Bad refund policies are infuriating. You feel like the company is just
          trying to rip you off. We never want our customers to feel that way,
          so our refund policy is simple: If you’re ever unhappy with our
          products for any reason, just contact us{" "}
          <strong>info@tappme.se</strong> and we'll take care of you.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          We are sorry to hear that you are returning an item. But do not worry,
          we, at{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>
          , will guide and assist you, in every way possible!
        </p>
        <h3>
          <strong>Return</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          We want you to be completely satisfied with your purchase. If you have
          any problems with this, you can return the product to us and we will
          either repair/replace it. You are eligible for a exchanges which are
          subject to the below terms.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          All Items are eligible for return and will be processed subject to
          certain conditions.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          Items that were marked as a gift when purchased is not allowed to be
          Returned or Exchanged.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          In case of the return of the items, we will cover all costs associated
          with the return.
        </p>
        <h3>
          <strong>Refund</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          In order to be eligible for a refund, you have to return the product
          within 30 days of your purchase. If the product is damaged/spoiled in
          any way, or you have initiated the return after 30 days have passed,
          you will not be eligible for a refund.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          In order for the product to be eligible for a refund, make sure these
          conditions are met:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          <li>Product must have the receipt or proof of purchase</li>
          <li>
            The customer must explain exactly what he was not satisfied with and
            why is requesting a refund
          </li>
        </ul>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          Once the item have been received and checked by our staff, a refund
          will be authorised by the same method that the payment was made.
          Depending on your financial institution, refunds can take up to 60
          days to be credited to your original payment method. In all cases we
          have the right to suspend the refund until the items are received back
          and inspected.
        </p>
        <h3>
          <strong>Contact us</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
          }}
        >
          For any questions or queries regarding this Refund Policy, please feel
          free to contact us:
        </p>
        <div className="ms-3">
          <strong>
            <a
              href="mailto:info@tappme.se"
              style={{ color: "blue" }}
              target="_blank"
            >
              info@tappme.se
            </a>
          </strong>
        </div>{" "}
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="logog p-4 pt-lg-5">
                    <img src="assets/img/tappme-logo.png" />
                    <p class="mt-4 mb-3 text-color">
                      Try TAPPME Startup/ Professional Plans
                    </p>
                    <h4 class="fw-normal mt-4">15 days Free</h4>
                    <p class="mt-3 mb-3 text-color">
                      After free trial $475/year will be charged
                    </p>
                    <div class="border py-4 px-3">
                      <p class="d-flex justify-content-between align-items-center text-dark mb-3">
                        TAPPME Startup/Professional Plans
                        <span class=" text-dark">14 days free</span>
                      </p>
                      <p class="d-flex justify-content-between align-items-center text-black-50">
                        Quantity: 10{" "}
                        <span class="text-black-50">$475/year</span>
                      </p>
                      <hr />
                      <p class="d-flex justify-content-between align-items-center text-gray mb-3">
                        Subtotal <span class="">$475</span>
                      </p>

                      <p class="d-flex justify-content-between align-items-center text-primary mt-3">
                        Add promotion code{" "}
                        <span class="w-50">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{
                    boxShadow: "0px 4px 22.2px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div class="logog p-4 px-lg-5 pt-lg-5">
                    <h4 class="fw-normal">Enter payment details</h4>

                    <div class="mb-3 mt-5">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      {/* <section class="footer-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-sm-6">
              <div class="single-footer-widget">
                <a href="#" class="logo">
                  <img src="assets/img/tappme-logo2.png" alt="tappme-logo2" />
                </a>
                <p class="mb-5 text-white">
                  TAPP ME is a product of MITAWAY AB. It is your all-in-one
                  solution designed to streamline registration processes,
                  enhance workflow visibility, and drive productivity across a
                  wide range of industries
                </p>
                <img src="assets/img/badge.png" alt="tappme-logo2" />
                <img src="assets/img/badge2.png" alt="tappme-logo2" />
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-footer-widget pl-5">
                <h3>Quick Links</h3>
                <ul class="list">
                  <li>
                    <a href="#">Home</a>{" "}
                  </li>
                  <li>
                    <a href="#">Services</a>{" "}
                  </li>
                  <li>
                    <a href="#">About us</a>{" "}
                  </li>
                  <li>
                    <a href="#">Contact us</a>{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-footer-widget">
                <h3>Get in Touch</h3>

                <ul class="info-list clearfix">
                  <li class="mb-4">
                    <i>
                      <img src="assets/img/mail.svg" alt="tappme-logo2" />
                    </i>{" "}
                    <a href="mailto:info@tandtftc.org" class="text-white">
                      office@mitaway.com
                    </a>
                  </li>
                  <li class="mb-4">
                    <i>
                      <img src="assets/img/phone.svg" alt="tappme-logo2" />
                    </i>
                    <a href="tel:+46 73 144 42 38" class="text-white">
                      +46 73 144 42 38
                    </a>
                  </li>
                  <li>
                    <i>
                      <img src="assets/img/location.svg" alt="tappme-logo2" />
                    </i>{" "}
                    Åkerbyvägen 368187 38 Stockholm, Sweden
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="copy-right">
        <div class="container">
          <div class="copy-right-content">
            <div class="row">
              <div class="col-lg-6 col-sm-6">
                <p class="text-white">
                  Copyright December 17, 2023©{" "}
                  <a
                    href="https:www.mitaway.com/"
                    target="_blank"
                    class="text-white"
                  >
                    MITAWAY AB{" "}
                  </a>{" "}
                  | ALL RIGHTS RESERVED{" "}
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 single-footer-widget">
                <ul className="social-list">
                  <li>
                    <a href="www.facebook.com" target="_blank">
                      <img src="/assets/img/facebook.svg" alt="tappme-logo2" />
                    </a>
                  </li>
                  <li>
                    <a href="www.linkedin.com" target="_blank">
                      <img src="/assets/img/linkedin.svg" alt="tappme-logo2" />
                    </a>
                  </li>
                  <li>
                    <a href="www.instagram.com" target="_blank">
                      <img src="/assets/img/instagram.svg" alt="tappme-logo2" />
                    </a>
                  </li>
                  <li>
                    <a href="www.twitter.com" target="_blank">
                      <img src="/assets/img/twitter.svg" alt="tappme-logo2" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default RefundPolicy;
