/*global google*/
import { useEffect, useRef } from "react";
import './style.css'

const ShowGoogleMap = (prop) => {
    console.log("prop -", prop.data)
    const effectRan = useRef(false);
    useEffect(() => {
        if (effectRan.current === false) {
            const script = document.createElement('script');

            script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdAoXQ2RWPZZF71oywO6Zfc0RwXdPN57A&callback=initMap&libraries=drawing";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
                effectRan.current = true;
            }
        }
    }, []);
    function initMap() {
        var map = new google.maps.Map(document.getElementById('showmap'), {
            center: { lat: prop.data.centerLatitude, lng: prop.data.centerLongitude }, // New York coordinates (change to your desired center)
            zoom: parseInt(prop.data.zoomLevel) // Adjust zoom level as needed
        });
        var drawingData = prop.data.polygonVertices
        new google.maps.Polygon({
            path: drawingData,
            map: map
        });


    }
    window.initMap = initMap;

    return (<>
        <div id="showmap" style={{ width: "100%", height: "600px", }}></div>
    </>);
}

export default ShowGoogleMap;