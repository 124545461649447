import React, { useEffect, useState } from "react";
import Header from "../policies/header";
import { Link } from "react-router-dom";
import Footer from "../policies/footer";
import { truncateName150 } from "../helper/StringSlicer";
import TutorialServices from "./services";
import { MDBContainer } from "mdb-react-ui-kit";

const Tutorials = () => {
  const [tutorialsData, setTutorialsData] = useState([]);

  const getTutorials = () => {
    TutorialServices.getTutorials()
      .then((response) => {
        setTutorialsData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getTutorials();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  return (
    <>
      <Header />
      <div class="page-title-area">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>Tutorials</h2>
                <ul>
                  <li>{/* <a href="/">Home</a> */}</li>
                  {/* <li href="/blogs">Blog</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="default-shape">
          <div class="shape-1">
            <img src="/assets/img/shape/1.png" alt="image" />
          </div>

          <div class="shape-2 rotateme">
            <img src="/assets/img/shape/2.png" alt="image" />
          </div>

          <div class="shape-3">
            <img src="/assets/img/shape/3.svg" alt="image" />
          </div>

          <div class="shape-4">
            <img src="/assets/img/shape/4.svg" alt="image" />
          </div>

          <div class="shape-5">
            <img src="/assets/img/shape/5.png" alt="image" />
          </div>
        </div>
      </div>

      <section class="blog-area pb-70 pt-5">
        <div class="container">
          <div class="section-title">
            <h2>Watch our Tutorials</h2>
            <div class="bar"></div>
          </div>

          <div class="row">
            {tutorialsData?.map((item) => (
              <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                  <div class="image">
                    <MDBContainer>
                      <div className="ratio ratio-16x9">
                        <iframe
                          src={item.link}
                          title={item.title}
                          allowfullscreen
                        ></iframe>
                      </div>
                    </MDBContainer>
                  </div>
                  <Link to={`/tutorials/${item.slug}`}>
                    <div class="content">
                      <h3>
                        <span>{item.title}</span>
                      </h3>
                      <p>
                        {truncateName150(item.subTitle)}
                        <Link to={`/tutorials/${item.slug}`} class="read-more">
                          Read More
                        </Link>
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="logog p-4 pt-lg-5">
                    <img src="assets/img/tappme-logo.png" />
                    <p class="mt-4 mb-3 text-color">
                      Try TAPPME Startup/ Professional Plans
                    </p>
                    <h4 class="fw-normal mt-4">15 days Free</h4>
                    <p class="mt-3 mb-3 text-color">
                      After free trial $475/year will be charged
                    </p>
                    <div class="border py-4 px-3">
                      <p class="d-flex justify-content-between align-items-center text-dark mb-3">
                        TAPPME Startup/Professional Plans
                        <span class=" text-dark">14 days free</span>
                      </p>
                      <p class="d-flex justify-content-between align-items-center text-black-50">
                        Quantity: 10{" "}
                        <span class="text-black-50">$475/year</span>
                      </p>
                      <hr />
                      <p class="d-flex justify-content-between align-items-center text-gray mb-3">
                        Subtotal <span class="">$475</span>
                      </p>

                      <p class="d-flex justify-content-between align-items-center text-primary mt-3">
                        Add promotion code{" "}
                        <span class="w-50">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{
                    boxShadow: "0px 4px 22.2px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div class="logog p-4 px-lg-5 pt-lg-5">
                    <h4 class="fw-normal">Enter payment details</h4>

                    <div class="mb-3 mt-5">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default Tutorials;
