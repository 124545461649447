import { Outlet, useLocation } from "react-router-dom";
import Sidenav from "./sidenav";
import Topnav from "./topnav";
import { createContext, useEffect, useState } from "react";
import { Useprovider } from "./stateManagementAPi";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import tEn from "../locales/en/translation.json";
import tfr from "../locales/fr/translation.json";
import tse from "../locales/se/translation.json";
const Dashboard = () => {
  const [data, setData] = useState("");
  const [linkVlaue, setLinkVlaue] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (location.state == null) {
      setLinkVlaue(localStorage.getItem("pathLink"));
    } else {
      localStorage.setItem("pathLink", location.state.pathLink);
      setLinkVlaue(localStorage.getItem("pathLink"));
    }
  }, []);

  const lang = localStorage.getItem("lang");
  if (lang == null) {
    localStorage.setItem("lang", "en");
  }

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: {
          translation: tEn,
        },
        fr: {
          translation: tfr,
        },
        se: {
          translation: tse,
        },
      },
      lng: lang, // if you're using a language detector, do not define the lng option
      fallbackLng: "en",

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });

  return (
    <>
      <Useprovider.Provider value={{ path: [linkVlaue, setLinkVlaue] }}>
        <div className="wrapper">
          <Topnav />
          <Sidenav />
          <main className="page-content">
            <Outlet />
          </main>
        </div>
      </Useprovider.Provider>
    </>
  );
};

export default Dashboard;
