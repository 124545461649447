import { useEffect, useState } from "react"
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
const StatusNavigator = () => {
    const [connection, setConnection] = useState(true)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState()
    useEffect(() => {
        const handleOnline = () => {
            setConnection(true);
            setOpen(false); // Hide the Snackbar when online
        };

        const handleOffline = () => {
            setConnection(false);
            setOpen(true);
            setMessage("No Internet Connection");
            setSeverity("error");
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default StatusNavigator