import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ServerSideTable from "../../../common components/serverSideTable";
import BillingServices from "./services";

const BillingCompany = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const columns = [
    { field: "workerId", headerName: "Worker Id", width: 100 },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "workinghours",
      headerName: "Working Hours",
      flex: 1,
      // renderCell: (param) => {
      //   function changesupervisorStatus(email, event) {
      //     console.log("status", !event);
      //     Worker.supervisorStatus(email, event)
      //       .then((respons) => {
      //         setOpen(true);
      //         setMessage(respons.data.message);
      //         setSeverity("success");
      //         // changeList();
      //       })
      //       .catch((e) => {
      //         console.log(e.response.data.status);
      //         if (e.response.data.status == false) {
      //           setOpen(true);
      //           setMessage(e.response.data.message);
      //           setSeverity("error");
      //         }
      //       });
      //   }
      //   return (
      //     <Switch
      //       lable="test"
      //       color="success"
      //       checked={param.row.isSupervisor}
      //       onChange={(e) =>
      //         changesupervisorStatus(param.row.username, e.target.checked)
      //       }
      //     />
      //   );
      // },
    },
    { field: "payrate", headerName: "Payrate", flex: 1 },
    { field: "totalpayments", headerName: "Total Payment", flex: 1 },
    {
      field: "mode",
      headerName: "Mode of Payment",
      flex: 1,
      renderCell: (param) => {
        return <span>{param.row.userProfile.address}</span>;
      },
    },
    {
      field: "status",
      headerName: "Payment Status",
      flex: 1,
      // renderCell: (param) => {
      //   function changeStatus(email, event) {
      //     console.log("status", !event);
      //     Worker.changeStatus(email, event)
      //       .then((respons) => {
      //         setOpen(true);
      //         setMessage(respons.data.message);
      //         setSeverity("success");
      //         changeList();
      //       })
      //       .catch((e) => {
      //         console.log(e.response.data.status);
      //         if (e.response.data.status == false) {
      //           setOpen(true);
      //           setMessage(e.response.data.message);
      //           setSeverity("error");
      //         }
      //       });
      //   }
      //   return (
      //     <Switch
      //       lable="test"
      //       color="success"
      //       checked={param.row.status}
      //       onChange={(e) => changeStatus(param.row.username, e.target.checked)}
      //     />
      //   );
      // },
    },
    {
      field: "view",
      headerName: "",
      renderCell: (param) => {
        return (
          // <Link
          //   className="outline-primary"
          //   state={{ data: param.row }}
          //   to="/dashboard/company/viewWokers"
          // >
          //   View Details
          // </Link>
          <Link
            className="link-with-border" // Add your custom class here
            // state={{ data: param.row }}
            // to="/dashboard/company/viewPayments"
          >
            View Details
          </Link>
        );
      },
      flex: 1,
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      // setPage((prevData) => {
      //   if (prevData !== e.page) {
      //     return e.page == undefined ? 0 : e.page
      //   }

      // });
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
  }

  const getBilling = async () => {
    debugger;
    try {
      const companyId = 5;
      const res = await BillingServices.getBillingData(companyId);
      const { totalPages, totalElements, currentPage, content } = res.data.data;
      if (res.status == 200) {
        setCount(totalPages);
        setTotalItems(totalElements);
        setData(content);
        setLoading(false);
      } else if (res.status == 400) {
        setMessage("No data found");
        setSeverity("error");
        setOpen(true);
      } else if (res.status == 500) {
        setMessage("Server Error");
        setSeverity("error");
        setOpen(true);
      }
    } catch (error) {
      console.log("UnExpected Error occur", error);
      setMessage("UnExpected Error occur");
      setSeverity("error");
      setOpen(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompanies();
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    if (page !== undefined) {
      getBilling();
    }
  }, [page, pageSize]);
  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
            <div className="col-12 col-lg-6 col-xl-6">
              <h5 className="mb-0 font-18 ">
                <strong> {t("Billing And Payment")}</strong>
              </h5>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 text-right">
              {/* <button
                class="btn btn-outline-secondary dropdown-toggle btn-blue font-14 ml-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Payment Sent
              </button>
              <ul class="dropdown-menu dropdown-menu-end border-b">
                <li>
                  <a class="dropdown-item" href="#">
                    Assign Tasks
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    Assign Tasks
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <ServerSideTable
          pageSize={10}
          page={0}
          count={count}
          functionName={getCompanies}
          data={data}
          columns={columns}
          loading={loading}
          idname="workerId"
        />
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default BillingCompany;
