import http from "../../baseURL/httpcommon";
import authHeader from "../../baseURL/authHeader";

const signIn = (data) => {
  return http.post("auth/login", data);
};

const companySignin = {
  signIn,
};

export default companySignin;
