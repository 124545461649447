import { useEffect, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SiteApi from '../sites/services';
import { jwtDecode } from 'jwt-decode';
import { Box, Chip, Paper, Skeleton } from '@mui/material';
import WorkForceApi from './services';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import swal from "sweetalert";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';




const AddWorkFroce = () => {
    const jwt = jwtDecode(localStorage.getItem("token"));
    const companyId = jwt.companyId
    const [show, setShow] = useState(false);
    const navigation = useNavigate()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const location = useLocation()
    // console.log("location", location.state.data)

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const dialogClose = () => {
        setOpen(false);
    };



    const [getBoundaryValue, setGetBoundaryValue] = useState([])

    function getSite() {
        SiteApi.getBoundary(companyId).then((respons) => {
            setGetBoundaryValue(respons.data.data)
        })
    }

    const [getInactivesupervisors, setInactivesupervisors] = useState([])

    function getSupervisors() {
        WorkForceApi.supervisors(companyId).then((respons) => {
            setInactivesupervisors(respons.data.data)
        })
    }

    const [getInactiveWorker, setInactiveWorker] = useState([])

    function getWorker() {
        WorkForceApi.worker(companyId).then((respons) => {
            const { data } = respons.data
            const updatedData = data.map((item) => {
                return { ...item, id: item?.userId };
            });
            setInactiveWorker(data)
        })
    }

    const [load, setLoad] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoad(false)
        }, 2000)
    }, [])

    const columns = [
        {
            field: 'userId', headerName: 'ID', width: 20
        },
        {
            field: 'name', headerName: 'Name', flex: 1
        },
        {
            field: 'phoneNo', headerName: 'Phone number', flex: 1
        }
        ,
        {
            field: 'email', headerName: 'Email Address', flex: 1
        }
    ]
    const columnsView = [
        {
            field: 'userId', headerName: 'ID', width: 20
        },
        {
            field: 'name', headerName: 'Name', flex: 1
        },
        {
            field: 'phoneNo', headerName: 'Phone number', flex: 1
        }
        ,
        {
            field: 'email', headerName: 'Email Address', flex: 3
        },
        {
            field: 'Remove', headerName: 'Action', flex: 1,
            renderCell: (param) => {
                //return (<DeleteIcon onClick={() => deleteWorker(param)} />)
                if (location.state !== null && location.state.view == true) {
                    return (<DeleteIcon color="error" onClick={() => deleteWorkerApi(location.state.data.id, param.row.userId)} />)
                } else {
                    return (<DeleteIcon color="error" onClick={() => deleteWorker(param)} />)
                }
            }
        }
    ]

    const columnsTask = [
        {
            field: 'taskId', headerName: 'Task ID', width: 100
        },
        {
            field: 'name', headerName: 'Name', flex: 1
        },
        {
            field: 'createdBy', headerName: 'Created By', flex: 1
        }
        ,
        {
            field: 'createdAt', headerName: 'Created At', flex: 1,
            renderCell: (param) => {
                return dayjs(param.row.createdAt).format("DD/MMM/YYYY")
            }
        }
        ,
        {
            field: 'dueDate', headerName: 'Due Date', flex: 1,
            renderCell: (param) => {
                return dayjs(param.row.dueDate).format("DD/MMM/YYYY")
            }
        }
        ,
        {
            field: 'priority', headerName: 'Priority', width: 150, headerAlign: 'center',
            renderCell: (param) => {
                return <Chip label={param.row.priority} color={param.row.priority == "Low" ? "success" : param.row.priority == "Medium" ? "warning" : "error"} sx={{ width: "100%" }} />
            }
        }
    ]

    // Add WorkForce
    const saveData = {
        name: "",
        workerIds: [],
        supervisorId: "",
        siteId: "",
        createdBy: companyId,
    }

    const [data, setData] = useState(saveData)
    const [effectCount, setEffectCount] = useState(0);

    useEffect(() => {
        setEffectCount(prevCount => prevCount + 1)
        setTimeout(() => {
            if (location.state !== null) {
                const loadData = location.state.data;
                setData(prevdata => ({
                    ...prevdata,
                    name: loadData.name,
                    // workerIds: loadData.workers,
                    supervisorId: loadData.supervisorId,
                    siteId: loadData.siteId,
                }));
            }

        }, 1000)
    }, []); // Include location and location.state in the dependency array


    function saveWorkForce() {
        WorkForceApi.addWorkerForce(data).then((respon) => {
            if (respon.data.status) {
                navigation("/dashboard/company/workforce")
            }

        })
    }

    const updateButton = useRef(null)
    const updateJson = {
        id: location.state !== null && location.state.view == true ? location.state.data.id : null,
        supervisorId: data.supervisorId,
        name: data.name,
    }

    useEffect(() => {
        if (location.state !== null && location.state.view == true) {
            updateButton.current.disabled = true
            console.log("location.state.data", updateJson)
            if (location.state.data.supervisorId !== data.supervisorId || location.state.data.name !== data.name) {
                updateButton.current.disabled = false
            }
        }

    }, [data.supervisorId, data.name])

    const saveButton = useRef(null)

    useEffect(() => {
        if (saveButton.current !== null) {
            if (data.siteId !== "" && data.name !== "" && data.supervisorId !== "") {
                saveButton.current.disabled = false
            } else {
                saveButton.current.disabled = true
            }
        }

    }, [data])

    function updateWorkForce() {
        WorkForceApi.updateWorkerForce(updateJson).then((respon) => {
            if (respon.data.status) {
                navigation("/dashboard/company/workforce")
            }

        })
    }

    // Add WorkForce
    const [selectedWorker, setSelectedWorker] = useState([])
    const [workerdata, setWorkerdata] = useState([])
    const [searchData, setSearchData] = useState([])
    const datacheck = data.name !== ""


    function getAssinedWorker() {
        if (location.state !== null) {
            WorkForceApi.assignedWorker(location.state.data.id).then((respons) => {

                const { workers } = respons.data
                // const updatedData = workers.map((item) => {
                //     return { ...item, id: item?.userId };
                // });
                // console.log("getAssinedWorker", updatedData)

                setWorkerdata(workers)

            })
        }

    }

    function deleteWorkerApi(wordForceID, userId) {
        swal({
            title: "Are you sure?",
            text: `You want to Delete`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                WorkForceApi.removeWorker(wordForceID, userId).then((respons) => {
                    console.log(respons)
                    getAssinedWorker()
                    getWorker()
                    swal("Successfull", {
                        icon: "success",
                    });
                })
                    .catch((error) => {
                        console.log("error", error);
                    });
            }
        });
    }

    useEffect(() => {
        getAssinedWorker()
    }, [])


    // function setSelectedWorkerId() {
    //     setWorkerdata([])
    //     getInactiveWorker.map((item, i) => {
    //         const index = selectedWorker.indexOf(item.userId)
    //         if (index !== -1) {
    //             setWorkerdata(prevWorkerdata => [...prevWorkerdata, item]);
    //         }

    //     })
    //     setShow(false)
    // }

    function setSelectedWorkerId() {
        getInactiveWorker.map((item, i) => {
            const index = selectedWorker.indexOf(item?.userId)
            console.log("setSelectedWorkerId", selectedWorker.length, workerdata.length, selectedWorker[i] == workerdata.userId,)
            if (index !== -1) {
                setWorkerdata((prevWorkerdata) => {
                    // Check if the userId already exists in prevWorkerdata
                    const index = prevWorkerdata.findIndex(prevItem => prevItem.userId === item.userId);

                    if (index !== -1) {
                        // Update existing item if found
                        const updatedData = [...prevWorkerdata];
                        updatedData[index] = item;
                        return updatedData;
                    } else {
                        // Add new item if userId not found
                        return [...prevWorkerdata, item];
                    }
                });
            } else if (selectedWorker.length < workerdata.length) {
                setWorkerdata([])
                getInactiveWorker.map((item, i) => {
                    const index = selectedWorker.indexOf(item?.userId)
                    if (index !== -1) {
                        setWorkerdata(prevWorkerdata => [...prevWorkerdata, item]);
                    }
                })
            }

        })
        setShow(false)
    }


    function updateSelectedWorkerId() {
        const data = {
            id: location.state.data.id,
            workerIds: selectedWorker
        }
        WorkForceApi.updateWorker(data).then((respon) => {
            console.log(respon)
            getAssinedWorker()
            getWorker()
            setShow(false)
        })
    }

    //search filter MUI

    // useEffect(() => {
    //     setSearchData(getInactiveWorker);
    // }, [getInactiveWorker]);

    function searchWord(event) {
        const { name, value } = event.target
        const filteredData = getInactiveWorker.filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.phoneNo.toLowerCase().includes(value.toLowerCase()) ||
            item.email.toLowerCase().includes(value.toLowerCase())
        );
        setSearchData(filteredData);

    }

    useEffect(() => {
        debugger
        setEffectCount(prevCount => prevCount + 1)
        setTimeout(() => {
            debugger
            const dataCount = data.workerIds
            const updatedContent = dataCount.map((item) => {
                return { ...item, id: item?.userId };
            });
            console.log("workerdata", updatedContent)
            if (updatedContent.length !== 0 && getInactiveWorker.length !== 0 && updatedContent[0].id !== undefined) {
                setWorkerdata(updatedContent);
                const adddata = [...updatedContent, ...getInactiveWorker]

                console.log("selectedWorker.indexOf", adddata)
                setSearchData(adddata)
            } else {
                setSearchData(getInactiveWorker);
            }

        }, 2000)

    }, [datacheck == true, getInactiveWorker]);
    //search filter MUI

    //Delete Woker
    function deleteWorker(param) {
        console.log("location", param.id)
        var arrvalue = selectedWorker
        const checkArr = selectedWorker.indexOf(param.id)
        // if (checkArr == 0) {
        //     arrvalue.splice(checkArr, 1)
        // } else {
        //     arrvalue.splice(checkArr, checkArr)
        // }

        if (checkArr !== -1) {
            arrvalue.splice(checkArr, 1);
            console.log("Deleted worker:", param);
        } else {
            console.log("Worker not found:", param);
        }


        console.log("location", param.id, checkArr)
        setSelectedWorker(arrvalue)
        setWorkerdata([])
        getInactiveWorker.map((item, i) => {
            const index = selectedWorker.indexOf(item?.userId)
            if (index !== -1) {
                setWorkerdata(prevWorkerdata => [...prevWorkerdata, item]);
                // setWorkerdata((prevWorkerdata) => {
                //     const alreadyID = prevWorkerdata.some(items => items.userId === item.userId)
                //     if (alreadyID == false) {
                //         return [...prevWorkerdata, item]
                //     } else {
                //         return [item]
                //     }
                // });
            }

        })
    }
    useEffect(() => {
        setTimeout(() => {
            setData({ ...data, workerIds: selectedWorker })
        }, 500)

    }, [selectedWorker])
    //Delete Woker


    const selectedSiteValue = getBoundaryValue.map((item, i) => {
        const check = item.siteId == data.siteId
        if (check) {
            return i
        }
    })
    const filteredSelectedSiteValue = selectedSiteValue.filter(index => index !== undefined)

    const selectedSiteValue2 = getInactivesupervisors.map((item, i) => {
        const check = item?.supervisorId == data?.supervisorId
        if (check) {
            return i
        }
    })
    const filteredSelectedSiteValue2 = selectedSiteValue2.filter(index => index !== undefined)

    console.log("filteredSelectedSiteValue2", selectedSiteValue2)


    useEffect(() => {
        getSite()
        getSupervisors()
        getWorker()
    }, [])
    return (<>
        <div className="card radius-10 p-4 px-3">
            <div className="card-header bg-transparent border-0">
                <div className="row g-3 align-items-center">
                    <div className="col-6 col-md-2 col-lg-2">
                        <h5 className="mb-0">{location.state !== null && location.state.view == true ? "Edit Workforce" : "Add Workforce"}</h5>
                    </div>

                    <div className="col-6 col-md-10 col-lg-10">
                        <div className="text-right">
                            {location.state !== null && location.state.view == true ?
                                <button className="btn btn-outline-secondary btn-blue font-14 ms-2" type="button" ref={updateButton} onClick={updateWorkForce}>Update</button>
                                : <button className="btn btn-outline-secondary btn-blue font-14 ms-2" type="button" ref={saveButton} onClick={saveWorkForce}>Save</button>
                            }

                            <Link to="/dashboard/company/workforce" className="btn btn-outline-secondary btn-blue font-14 ms-2"><span className="badge bg-danger rounded-circle"></span>Cancel</Link>

                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">

                    <div className="col py-2">
                        <div className="row">
                            <div className="col-4 col-md-4 col-lg-4">
                                {
                                    load ? <Skeleton variant="rectangular" width={"100%"} height={56} /> :


                                        <TextField id="outlined-basic" label="Workforce Name  *" name="name" variant="outlined" sx={{ width: "100%" }} value={data.name} onChange={(e) => setData({ ...data, name: e.target.value })}
                                        //disabled={location.state !== null && location.state.view == true ? true : false}
                                        />
                                }

                            </div>
                            <div className="col-4 col-md-4 col-lg-4">
                                {
                                    load ? <Skeleton variant="rectangular" width={"100%"} height={56} /> :


                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={getBoundaryValue}
                                            sx={{ width: "100%" }}
                                            defaultValue={getBoundaryValue[filteredSelectedSiteValue[0]]}
                                            disabled={location.state !== null && location.state.view == true ? true : false}
                                            filterSelectedOptions
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option.siteName}
                                                </Box>
                                            )}
                                            getOptionLabel={(option) => option.siteName}
                                            onChange={(event, value) => {
                                                setData({ ...data, siteId: value == null ? "" : value.siteId })
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Site *" name="siteId" />}
                                        />
                                }

                            </div>
                            <div className="col-4 col-md-4 col-lg-4">
                                {
                                    load ? <Skeleton variant="rectangular" width={"100%"} height={56} /> :


                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={getInactivesupervisors}
                                            defaultValue={getInactivesupervisors[filteredSelectedSiteValue2[0]]}
                                            sx={{ width: "100%" }}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    {option.name}
                                                </Box>
                                            )}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, value) => {
                                                setData({ ...data, supervisorId: value == null ? "" : value.userId })
                                            }}
                                            renderInput={(params) => <TextField {...params} label={location.state !== null && location.state.view == true ? "Change Supervisor" : "Supervisor *"} name="supervisorId" />}
                                        />
                                }


                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 col-md-12 col-lg-12 mb3">
                                <div className="card radius-0">
                                    <div className="card-body border align-items-center p-lg-4">

                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                {
                                                    location.state !== null && location.state.view == true ?
                                                        <h5>Supervisor: {location.state.data.supervisorName}</h5> :
                                                        <h5>Added Workers <span style={{ fontSize: "12px", color: "#6d6d6d", fontWeight: "500" }}>(Remove if you added mistakenly)</span></h5>
                                                }
                                                {/* <ul style={{ display: "flex", margin: 0, listStyle: "none", padding: 0 }}>
                                                    {selectedWorker.map((item) =>
                                                        <li>{item},</li>
                                                    )}
                                                </ul> */}

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6 mb-4 text-right">
                                                <button className="btn btn-outline-secondary btn-blue font-14 ms-2" type="button" onClick={handleShow}>Assign Workers</button>
                                                <Modal
                                                    show={show}
                                                    onHide={handleClose}
                                                    backdrop="static"
                                                    size='xl'
                                                    keyboard={false}
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Select Workers  <span style={{ fontSize: "12px", color: "#6d6d6d", fontWeight: "500" }}>(Choose workers to add into workforce)</span></Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <TextField label="Search Worker" sx={{ width: "100%" }} onChange={(e) => searchWord(e)} />
                                                        <div className='mt-4' style={{ height: 400, width: '100%' }}>
                                                            <DataGrid
                                                                columns={columns}
                                                                rows={searchData}
                                                                getRowId={(row) => row.userId}
                                                                // initialState={{
                                                                //     pagination: {
                                                                //         paginationModel: { page: 0, pageSize: 15 },
                                                                //     },
                                                                // }}
                                                                // pageSizeOptions={[5, 10]}
                                                                checkboxSelection
                                                                rowSelectionModel={selectedWorker}
                                                                onRowSelectionModelChange={(params) => {
                                                                    setSelectedWorker(params)
                                                                }}
                                                                hideFooter
                                                            />
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleClose}>
                                                            Close
                                                        </Button>
                                                        {
                                                            location.state !== null && location.state.view == true ?
                                                                <Button variant="primary" onClick={updateSelectedWorkerId}>Update</Button>
                                                                :
                                                                <Button variant="primary" onClick={setSelectedWorkerId}>Add</Button>
                                                        }

                                                    </Modal.Footer>
                                                </Modal>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12 col-12">

                                                {
                                                    load ? <Skeleton variant="rectangular" width={"100%"} height={400} /> :
                                                        <div style={{ height: 425, width: '100%' }}>
                                                            <DataGrid
                                                                columns={columnsView}
                                                                rows={workerdata ? workerdata : null}
                                                                getRowId={(row) => row.userId}
                                                                initialState={{
                                                                    pagination: {
                                                                        paginationModel: { page: 0, pageSize: 15 },
                                                                    },
                                                                }}
                                                                pageSizeOptions={[5, 10]}
                                                                localeText={{ noRowsLabel: "Please Add Workers" }}
                                                            />

                                                        </div>
                                                }
                                            </div>
                                        </div>

                                        {
                                            location.state !== null && location.state.view == true ? <>
                                                <div className='row mt-5'>
                                                    <div className="col-12 col-md-6 col-lg-6 mb-4">
                                                        <h5>Tasks List</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-12 col-12">
                                                        <div style={{ height: 420, width: '100%' }}>
                                                            <DataGrid
                                                                columns={columnsTask}
                                                                rows={location.state.data.tasks}
                                                                getRowId={(row) => row.taskId}
                                                                initialState={{
                                                                    pagination: {
                                                                        paginationModel: { page: 0, pageSize: 15 },
                                                                    },
                                                                }}
                                                                pageSizeOptions={[5, 10]}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            </> : null

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogClose}>Disagree</Button>
                <Button onClick={dialogClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>

    </>)
}

export default AddWorkFroce