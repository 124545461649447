import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getNotification = (workforceId) => {
  debugger;
  return http.get(`/notification/worker/${workforceId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

// const findNotificationById = (id) => {
//   return http.get(`/notification/${id}`);
// };

const NotificaionServices = {
  getNotification,
  // findNotificationById,
};

export default NotificaionServices;
