import React, { useEffect, useState } from "react";
import SubscriptionServices from "./services";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ServerSideTable from "../../../common components/serverSideTable";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CompanySubcription = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const [show, setShow] = useState(false);

  const [currentSubData, setCurrentSubData] = useState({});

  const jwt = jwtDecode(localStorage.getItem("token"));
  const userId = jwt.id;
  const companyId = jwt.companyId;
  console.log("userID", userId);

  const columns = [
    { field: "transactionId", headerName: "Transaction Id", width: 200 },
    { field: "currency", headerName: "Currency", width: 100 },
    {
      field: "amount",
      headerName: "Paid Amount",
      width: 150,
      valueFormatter: (params) => {
        // Ensure the value is a number and format it to two decimal places
        return params.value ? params.value.toFixed(2) : "";
      },
    },
    { field: "paymentStatus", headerName: "Payment Status", width: 150 },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      valueFormatter: (params) => {
        // Convert the timestamp to a date object
        const date = new Date(params.value * 1000); // Multiply by 1000 to convert seconds to milliseconds
        // Format the date
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      },
    },
    {
      field: "paymentMethodTypes",
      headerName: "Payment Methods",
      width: 150,
      valueFormatter: (params) => {
        // Join the array elements into a comma-separated string
        return params.value ? params.value.join(", ") : "";
      },
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
  }

  //get invoice records
  const getInvoice = async () => {
    debugger;
    try {
      const res = await SubscriptionServices.getInvoiceData(
        page,
        pageSize,
        userId
      );
      debugger;
      const { totalPages, totalElements, currentPage, content } = res.data.data;
      console.log("data_invoice" + res.data.data);
      setCount(totalPages);
      setTotalItems(totalElements);
      setInvoiceData(content);
    } catch (error) {
      setMessage(error.message);
      setSeverity("error");
      // setOpen(true);
    }
    setLoading(false);
  };
  //get subscription record
  const getSubscription = async () => {
    debugger;
    try {
      const response = await SubscriptionServices.getSubscriptionData();

      if (response.status == 200) {
        debugger;
        const subData = response.data.data;
        console.log("userCurrentSub", subData);
        setSubscription(subData);
      }
    } catch (error) {
      //   if (error.response.status === 404) {
      //     setMessage(error.response.data.message);
      //     setSeverity("error");
      //     setOpen(true);
      //   } else if (error.response.status === 400) {
      //     console.log("Missing Parameter");
      //   } else if (error.response.status === 500) {
      //     console.log("Internal server error");
      //   }
      setMessage(error.message);
      setSeverity("error");
      // setOpen(true);
    }
  };

  useEffect(() => {
    getCompanies();
    getCompanyCurrentSubscription();
    window.scrollTo(0, 100);
  }, []);

  useEffect(() => {
    if (page !== undefined) {
      getInvoice();
      getSubscription();
    }
  }, [page, pageSize]);

  const getCompanyCurrentSubscription = () => {
    SubscriptionServices.getCompanyCurrentSubscription(userId)
      .then((response) => {
        setCurrentSubData(response.data.data);
        console.log("currentSub", response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-6 col-lg-6">
              <h5 className="mb-0">Subscriptions</h5>
            </div>
            <div className="col-6 col-md-6 col-lg-6 text-right"></div>
          </div>
        </div>
        <div className="card-body">
          <div className="row g-3 align-items-center">
            <div className="col-12 col-md-7 col-lg-7">
              <div className="border p-4 mb-4 border-20">
                <div className="d-flex mb-3 xs-block">
                  <label className="form-label text-dark font-18 xs-block">
                    {subscription.planName} Plan
                  </label>{" "}
                  {/* <span className="text-gray font-14 ps-lg-3">$8/month</span> */}
                  {/* <span className="text-gray font-14 ps-lg-3">
                    {subscription.startupAmount
                      ? subscription.startupAmount
                      : 0}{" "}
                    /month
                  </span> */}
                  <div className="ms-auto xs-block">
                    <span className="mb-0 font-18">
                      ${subscription.price ? subscription.price : 0}{" "}
                    </span>
                    <span className="text-gray font-14">
                      {subscription.planType
                        ? subscription.planType
                        : "/monthly"}
                    </span>
                  </div>
                </div>
                <div className="d-flex mb-3 xs-block">
                  <label className="text-gray font-14 xs-block">
                    Active Licenses:{" "}
                    {subscription.activeUserCount
                      ? subscription.activeUserCount
                      : 0}
                  </label>{" "}
                  <span className="text-gray font-14 ms-lg-4">
                    Remaining Licenses:{" "}
                    {subscription.remainingCount
                      ? subscription.remainingCount
                      : 0}
                  </span>
                  <div className="ms-auto xs-block">
                    {/* <span className="text-gray font-14">
                      Remaining Licenses: 3
                    </span> */}
                  </div>
                </div>
                <div className="d-flex mb-3 mt-lg-5 xs-block">
                  <Link
                    className="btn btn-primary font-14"
                    // to={{
                    //   //dummy redirection
                    //   pathname: "/dashboard/company/home",
                    //   // state: {
                    //   //   data: param.row,
                    //   //   view: true,
                    //   // },
                    // }}
                    title="Click to Upgrade Plan"
                  >
                    {"Upgrade Plan"}
                  </Link>
                  {/* <button
                    type="button"
                    className="btn btn-danger py-1 font-14 ms-lg-4"
                    onClick={() => setShow(true)}
                  >
                    Cancel Subscription
                  </button> */}
                  <div className="ms-auto">
                    <span className="text-blue font-14">
                      Monthly renewal:{" "}
                      {subscription.renewalDate ? subscription.renewalDate : 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mb-3 mt-lg-5">Invoices</h5>
          <div className="table-responsive">
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={invoiceData}
              columns={columns}
              loading={loading}
              idname="id"
            />
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanySubcription;
