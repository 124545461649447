import http from '../../../../baseURL/httpcommon'
import authHeader from '../../../../baseURL/authHeader'

const addBoundary = (data) => {
    return http.post(`company/create/site`, data, {
        headers: {
            ...authHeader(),
        },
    })
}
const editBoundary = (id, data) => {
    return http.put(`company/update/site/${id}`, data, {
        headers: {
            ...authHeader(),
        },
    })
}
const getBoundary = (userID) => {
    return http.get(`company/site/${userID}`)
}

const SiteApi = {
    addBoundary,
    editBoundary,
    getBoundary
}

export default SiteApi