import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import companySignUp from "../landingPage/services";
import Swal from "sweetalert2";
import { Alert, Snackbar, Stack } from "@mui/material";

const Footer = () => {
  const messsageDataSchema = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };
  const [validated, setValidated] = useState(false);
  const [messsageData, setMesssageData] = useState(messsageDataSchema);
  const [showForm, setShowForm] = useState(false);
  const [contactValidated, setContactValidated] = useState(false);
  const handleShowForm = () => setShowForm(true);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleCloseForm = () => {
    setShowForm(false);
    setContactValidated(false);
  };

  const handleClosse = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setValidated(false);
  };

  function typeMessage(event) {
    const { name, value } = event.target;
    const phoneRegex = /^[0-9\b]+$/;
    // Check if the input value matches the phone number regex

    if (name === "phoneNumber") {
      if (phoneRegex.test(value) || value === "") {
        setMesssageData({ ...messsageData, [name]: value });
      }
    } else {
      setMesssageData({ ...messsageData, [name]: value });
    }

    console.log("typeMessage", messsageData);
  }

  const contactFormSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (
        (messsageData.name !== "",
        messsageData.email !== "",
        messsageData.phoneNumber !== "",
        messsageData.message !== "")
      ) {
        sendMessage();
      }
    }

    setContactValidated(true);
  };

  function sendMessage() {
    companySignUp
      .sendMessageApi(messsageData)
      .then((respons) => {
        console.log(respons);
        Swal.fire({
          // position: "top-end",
          icon: "success",
          title: "Message sent successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
        setShowForm(false);
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        setSeverity("error");
        setOpen(true);
        console.log("error", error.response.data.message);
      });
  }

  return (
    <>
      <section id="footer" className="footer-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-6">
              <div className="single-footer-widget">
                <a href="/" className="logo">
                  <img
                    src="/assets/img/tappme-logo2.png"
                    alt="tappme-logo2"
                    height={90}
                    width={150}
                  />
                </a>
                <p className="mb-5 text-white">
                  TAPP ME is a product of MITAWAY AB. It is your all-in-one
                  solution designed to streamline registration processes,
                  enhance workflow visibility, and drive productivity across a
                  wide range of industries
                </p>
                <img
                  src="/assets/img/badge.png"
                  alt="tappme-logo2"
                  width={150}
                  height={90}
                />
                {"  "}
                <img
                  src="/assets/img/badge2.png"
                  alt="tappme-logo2"
                  width={150}
                  height={90}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Quick Links</h3>
                <ul className="list">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/aboutus">About us</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/cookies">Cookies Policy</a>
                  </li>
                  <li>
                    <a href="/refundpolicy">Refund Policy</a>
                  </li>
                  <li>
                    <a href="/eula">Eula</a>
                  </li>
                  <li>
                    <a href="/termscondition">Terms and Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget">
                <h3>Get in Touch</h3>
                <ul className="info-list clearfix">
                  <li className="mb-4">
                    <i>
                      <img src="/assets/img/mail.svg" alt="tappme-logo2" />
                    </i>{" "}
                    <a href="mailto:info@tandtftc.org" className="text-white">
                      office@mitaway.com
                    </a>
                  </li>
                  <li className="mb-4">
                    <i>
                      <img src="/assets/img/phone.svg" alt="tappme-logo2" />
                    </i>{" "}
                    <a href="tel:+46 73 144 42 38" className="text-white">
                      +46 73 144 42 38
                    </a>
                  </li>
                  <li>
                    <i>
                      <img src="/assets/img/location.svg" alt="tappme-logo2" />
                    </i>{" "}
                    Åkerbyvägen 368187 38 Stockholm, Sweden
                  </li>
                  {/* <li>
                    <i>
                      <img src="/assets/img/location.svg" alt="tappme-logo2" />
                    </i>{" "}
                    Contact us
                  </li> */}
                  <div className="pricing-btn ps-3 mt-5">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleShowForm()}
                      className="default-btn3 px-5"
                    >
                      Contact us
                    </span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copy-right">
        <div className="container">
          <div className="copy-right-content">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <p className="text-white">
                  Copyright December 17, 2023© MITAWAY AB | ALL RIGHTS RESERVED
                </p>
              </div>
              <div className="col-lg-6 col-sm-6 single-footer-widget">
                <ul className="social-list">
                  <li>
                    <a href="https://www.facebook.com/@Tappme" target="_blank">
                      <img
                        src="/assets/img/facebook.svg"
                        alt="tappme-logo2"
                        rel="noopener noreferrer"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/@Tappme"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/assets/img/instagram.svg" alt="tappme-logo2" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/@Tappme"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/assets/img/twitter.svg" alt="tappme-logo2" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showForm}
        onHide={handleCloseForm}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title
            style={{ color: "#000", fontSize: "18px", marginLeft: "5rem" }}
          >
            Connect with Us Through the Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={contactValidated}>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  maxLength="50"
                  onChange={(e) => typeMessage(e)}
                />
                <Form.Control.Feedback></Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom01"
                className="mt-3"
              >
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="Email"
                  name="email"
                  maxLength="50"
                  onChange={(e) => typeMessage(e)}
                />
                <Form.Control.Feedback></Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom01"
                className="mt-3"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="phoneNumber"
                  minLength="10"
                  maxLength="15"
                  value={messsageData.phoneNumber}
                  onChange={(e) => typeMessage(e)}
                />
                <Form.Control.Feedback></Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom01"
                className="mt-3"
              >
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="companyName"
                  value={messsageData.companyName}
                  onChange={(e) => typeMessage(e)}
                />
                <Form.Control.Feedback></Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                as={Col}
                md="12"
                controlId="validationCustom01"
                className="mt-3"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  required
                  type="number"
                  maxLength="1000"
                  name="message"
                  onChange={(e) => typeMessage(e)}
                />
                <Form.Control.Feedback></Form.Control.Feedback>
              </Form.Group>
            </Row>

            {/* <button class="btn btn-primary login-btn height-50 w-100" onClick={contactFormSubmit}>Submit</button> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseForm}>
            Close
          </Button>
          <Button variant="primary" onClick={contactFormSubmit}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClosse}
        >
          <Alert
            onClose={handleClosse}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default Footer;
