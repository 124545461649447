import { useEffect, useRef } from "react";
import Worker from "./services";
import { useState } from "react";
import { Button, Chip, Switch } from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import WorkForceApi from "./services";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

const WorkFroce = () => {
  const jwt = jwtDecode(localStorage.getItem("token"));
  const companyId = jwt.companyId;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const pageRef = useRef(null);
  function getCompanies(e) {
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      //   console.log("getUsers", page, pageSize)
      setPage(e.page == undefined ? 0 : e.page);
      // setPage((prevData) => {
      //   if (prevData !== e.page) {
      //     return e.page == undefined ? 0 : e.page
      //   }

      // });
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
    }
    // WorkForceApi.getworkerForce(page, pageSize, companyId).then((respons) => {
    //     const { totalPages, totalElements, currentPage, content } = respons.data.data
    //     setCount(totalPages)
    //     setTotalItems(totalElements)
    //     setData(content)
    //     setLoading(false)
    // })
  }

  function changeList() {
    console.log("changeList test 1", page, pageSize);
    WorkForceApi.getworkerForce(page, pageSize, companyId).then((respons) => {
      const { totalPages, totalElements, currentPage, content } =
        respons.data.data;
      setCount(totalPages);
      setTotalItems(totalElements);
      setData(content);
      setLoading(false);
    });
  }

  useEffect(() => {
    getCompanies();
    window.scrollTo(0, 100);
  }, []);
  useEffect(() => {
    if (page !== undefined) {
      changeList();
    }
  }, [page, pageSize]);

  const columns = [
    { field: "name", headerName: "Workforce Name", width: 180 },
    {
      field: "supervisorId",
      headerName: "Supervisor",
      width: 180,
      renderCell: (param) => {
        return <span>{param.row.supervisorName}</span>;
      },
    },
    {
      field: "companySites",
      headerName: "Site Name",
      width: 220,
      renderCell: (param) => {
        return <span>{param.row.siteName}</span>;
      },
    },
    {
      field: "workers",
      headerName: "Workers",
      width: 100,
      renderCell: (param) => {
        return <span>{param.row.workers}</span>;
      },
    },
    {
      field: "tasks",
      headerName: "Tasks",
      width: 100,
      renderCell: (param) => {
        return <span>{param.row.tasks.length}</span>;
      },
    },
    // {
    //   field: "status",
    //   headerName: "Is Enabled?",
    //   width: 100,
    //   renderCell: (param) => {
    //     function changeStatus(email, event) {
    //       // CompaniesApi.changeStatus(email, event).then((respons) => {
    //       //    setOpen(true);
    //       //    setMessage(respons.data.message);
    //       //    setSeverity("success");
    //       //    getCompanies()
    //       // })
    //     }
    //     return (
    //       <Switch
    //         lable="test"
    //         color="success"
    //         checked={param.row.status}
    //         onChange={(e) => changeStatus(param.row.email, e.target.checked)}
    //       />
    //     );
    //   },
    // },
    {
      field: "view",
      headerName: "",
      renderCell: (param) => {
        return (
          <Link
            to="/dashboard/company/addworkforce"
            state={{ data: param.row, view: true }}
            className="btn btn-outline-secondary btn-blue font-13 me-2"
          >
            <span className="badge bg-danger rounded-circle"></span> View
            Workforce
          </Link>
        );
      },
      width: 150,
    },
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //to get set the focus on whole page when scroolbar appear on page
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    pageRef.current.scrollIntoView({ behavior: "auto" });
  }, []);
  return (
    <>
      <div className="row" ref={pageRef}>
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card radius-10 w-100">
            <div className="card-body px-4">
              <div className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3 mb-3">
                <div className="col-12 col-lg-6 col-xl-6">
                  <h5 className="mb-0 font-18">{t("Workforce")}</h5>
                </div>
                <div className="col-12 col-lg-6 col-xl-6 text-right">
                  <Link
                    to="/dashboard/company/addworkforce"
                    className="btn btn-outline-secondary btn-blue font-14 ms-2"
                  >
                    <span className="badge bg-danger rounded-circle"></span> Add
                    Workforce
                  </Link>
                </div>
              </div>
              <ServerSideTable
                pageSize={10}
                page={0}
                count={count}
                functionName={getCompanies}
                data={data}
                columns={columns}
                loading={loading}
                idname="id"
              />
            </div>
          </div>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};
export default WorkFroce;
