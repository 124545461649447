import http from "../../baseURL/httpcommon";
import authHeader from "../../baseURL/authHeader";

const getTop = (data) => {
  return http.post("user/forgotpassword", data);
};
const resetPassword = (data) => {
  return http.post("user/resetpassword", data);
};

const ForgetApi = {
  getTop,
  resetPassword,
};

export default ForgetApi;
