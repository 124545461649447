import React from "react";
import Footer from "./footer";
import Header from "./header";
import { Link } from "react-router-dom";

const PrivacyPolicies = () => {
  return (
    <>
      <Header />
      <div class="container pt-3 pb-4">
        <h1>PRIVACY POLICY</h1>
        <p className="ms-4 mb-4">Last Updated: May 12, 2024</p>

        <h3 className="mt-4 ">INTRODUCTION</h3>
        <p
          className="ms-4 mb-3 font-15 py-2"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>
          ("our" "we" or "us") respect the privacy and are committed to protect
          the personal information of our Users ("user," "you," your" or
          "yours") when you visit or use{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>
          (the "App"). We provide our App and Services (hereinafter
          collectively, either "App" or "Services") to you as under the
          following privacy policy (the "Policy").
        </p>
        <h3 className="mb-3">Personal Information We Collect</h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          In order to keep the App functional, we must process information about
          you to operate, improve, modify, customize, support and market our
          services. If you do not agree with this, then you can update/delete
          your personal information:{" "}
          <strong>Logging in to their personal page</strong>
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          The type of information that we collect depends on how you use our App
          and Services. We require certain information to deliver to you, our
          services, and without this, we will not be able to provide such
          services to you. Our Services have optional features, which, if used
          by you, require us to collect additional information to provide such
          features. You will be notified of such collection, as appropriate. If
          you choose not to provide the information needed to use a feature, you
          will be unable to use the feature. Please carefully read the following
          to understand what information we may collect:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            When you use the App or register to the App, we may ask you to
            provide certain information, including{" "}
            <strong>
              Email, Name, Surname, Phone, Username, Password, Personal number,
              Address, Job title, Contact preferences, Billing addresses or
              Debit/credit card numbers
            </strong>
            . We will also collect or otherwise ask you to provide additional
            information that you provide us, such as your profile details,
            pictures and/or any other texts, images, videos or information that
            you may upload on the App and/or provide to the App.
          </li>
          <li>
            Your financial information when you make purchases on the App. We
            collect financial information when you make a purchase on the{" "}
            <a href="https://stripe.com/privacy" target="_blank">
              Stripe{" "}
            </a>
            or other payment providers.
          </li>
          <li>
            Geo-Location Data. We may use and store information about your
            location, if you give us permission to do so. If you are accessing a
            page or service from a computer or a mobile device, you may be asked
            to share your precise (GPS level) geo-location information with us
            so we can customize your experience on our services or on other
            services when we work with a marketing partner such as a third party
            service provider, advertiser, advertising network and platform,
            advertising agency, and a data broker or aggregator. If you agree to
            the collection of location data, in most cases, you will be able to
            turn off such data collection at any time by accessing the privacy
            settings of your mobile device
          </li>

          <li>
            We may also collect details about other social networks and linked
            accounts such as{" "}
            <a
              href="https://policies.google.com/privacy?hl=en-US"
              target="_blank"
            >
              Google
            </a>
            ,{" "}
            <a
              href="https://www.facebook.com/privacy/policy/version/20220104/"
              target="_blank"
            >
              Facebook{" "}
            </a>
            and also information about your communications with us as well as
            communication with other users of our App, and we receive certain
            information, which they provide, such as{" "}
            <strong>
              To create user accounts or To protect your site, prevent fraud
            </strong>
            .
          </li>
          <li>
            We also collect information while you access, browse, view or
            otherwise use the App. In other words, when you access the App we
            are aware of your usage of the App, and gather, collect and record
            the information relating to such usage, including geo-location
            information, IP address, device and connection information, browser
            information and web-log information, phone book and contacts, and
            all communications recorded by Users through the App. We use that
            information to enhance user experience, personalize your browsing
            experience as well as monitor the App for preventing fraud and
            inappropriate content or behavior. We collect texts, information,
            pictures, videos and/or any data that you may upload on the app. We
            also collect supplemental information obtained from third parties
            such as demographic and navigation data, if applicable. We combine
            your personal information with information we collect automatically
            or obtain from other companies and use it to improve and personalize
            our services, content and advertising and/or to prevent fraud.
          </li>
        </ul>
        <p
          className="ms-4 mb-4"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          All of the preceding is collected, as permitted by applicable laws
          relevant to your jurisdiction.
        </p>
        <h3 className="mb-4">
          <strong>How Do We Collect Personal Information?</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <strong>Information provided by you.</strong> You directly provide us
          with most of the information we collect. You do this by filling out
          the registration details on the App, linking accounts of other social
          networks to your{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          account, completing application forms, as well as by posting and
          sharing additional information voluntarily.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <strong>Information we collect when you use the App.</strong> We
          collect technical information indirectly and automatically through our
          systems. This information includes logging your operating system,
          software configuration, Internet Protocol (IP) address and use of
          cookies (cookies are small files sent from us to your computer and
          sometimes back). Cookies ultimately help us improve your navigation
          and ease of use of our App.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Should you require additional information about how we may use Cookies
          to improve the services we provide, please visit our Cookie Policy.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <strong>Information we collect from other sources.</strong> We may
          receive information from third party vendors and/or other commercially
          available sources, such as:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            Third party vendors who provide us data to supplement the
            information we collect about you, in accordance with applicable
            laws. For example, we receive information from advertising and
            marketing partners in order to manage and measure our campaigns as
            well as from enrichment service providers to enhance, modify and
            personalize your experience of the App.
          </li>
          <li>
            If you link, connect, or login to your to your social media accounts
            with us, with a third-party service (e.g.
            <a
              href="https://policies.google.com/privacy?hl=en-US"
              target="_blank"
            >
              Google
            </a>
            ,
            <a
              href="https://www.facebook.com/privacy/policy/version/20220104/"
              target="_blank"
            >
              Facebook
            </a>
            ), we will receive certain profile information about you from your
            social media provider. The profile information we receive may vary
            depending on the social media provider concerned, but will often
            include your name, email address, friends list, profile picture as
            well as other information you choose to make public on such social
            media platform. Please note that we do not control, and are not
            responsible for, other uses of your personal information by your
            third-party social media provider. We recommend that you review
            their privacy notice to understand how they collect, use and share
            your personal information, and how you can set your privacy
            preferences on their sites and apps.
          </li>
          <li>
            For more information, please see the following sections of this
            Policy:
            <strong>Analytics, Cookies</strong> and{" "}
            <strong>Marketing and Behavioral Remarketing</strong>
          </li>
        </ul>

        <h3 className="mb-3">
          <strong>How We Use Collected Personal Information?</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          All processing of your personal information will always be justified
          by a "lawful ground" for processing, where relevant under applicable
          laws. In the majority of cases, processing will be justified on the
          basis that:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            you, or in the case you are below the age of 16 or the age of legal
            adulthood if different in your country, your parent or guardian has
            provided consent for us to use your personal information for a
            specific purpose;
          </li>
          <li>
            the use of your personal information is necessary to perform a
            contract or take reasonable steps to enter into a contract with you
            <strong>
              (e.g. to provide you with agreements or services which you have
              purchased)
            </strong>
            ;
          </li>
          <li>
            the processing is necessary to comply with a relevant legal
            obligation or regulatory obligation that you and/or we have (e.g.
            fraud prevention); or
          </li>
          <li>
            the processing is necessary to support our legitimate interests as a
            business (e.g. to improve our services to you), subject to your
            interests and fundamental rights and provided it is conducted at all
            times in a way that is proportionate.
          </li>
        </ul>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We will use your personal information for the following purposes:
        </p>

        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>To create user accounts.</li>

          <li>
            We use information we have to verify accounts and activity; combat
            harmful conduct, protect users against bad experiences and spam; and
            promote safety, security and integrity on and off our Services, such
            as by investigating suspicious activity or violations of our Terms
            and policies, and to ensure our Services are being used legally.
            Safety, security and integrity are an integral part of our Services.
          </li>
        </ul>

        <h3 className="mb-3">
          <strong>How Long Do We Retain Your Personal Information?</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We retain personal information only for as long as is required to
          fulfil the purpose for which it was collected and unless otherwise
          stated for a period of:{" "}
          <strong>as long as the user has an account with us</strong>. Please
          note that some circumstances, we will retain your personal information
          for longer periods of time. We will retain personal information for
          the following purposes:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            as long as it is necessary and relevant for our operations, e.g. as
            long as is necessary to provide services, etc.; and
          </li>
          <li>
            in relation to personal information to comply with applicable laws,
            prevent fraud, troubleshoot problems, assist with any investigation,
            enforce our App terms and take other actions as permitted by law.
          </li>
        </ul>

        <h3 className="mb-3">
          <strong>Children under the legal Age of adulthood</strong>
        </h3>

        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Our services are not directed to children under the age of thirteen,
          or any as such age relevant to Applicable Laws in your jurisdiction,
          and we do not knowingly collect personal information from children
          under the age of thirteen, or sixteen, through our Services. We
          encourage parents and legal guardians to help enforce our Privacy
          Notice by instructing children under the age of thirteen not to
          download or use our online services.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Should we learn we have collected or received personal information
          from a child under 13 or any such age relevant to Applicable Laws in
          your jurisdiction, we shall delete that personal information
          immediately and if we shall have a reasonable doubt that the consent
          given is not truthful regarding the age of the user, we reserve
          ourselves the right to immediately suspend the access to the App
          giving notice of the reasons. If you believe we might have any
          information from or about a child under 13 or 16, or if you are a
          parent or guardian concerned that we may have collected information of
          your child, please contact us immediately at info@tappme.se
        </p>
        <h3 className="mb-3">
          <strong>Sharing Personal Information with Third Parties</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We do not sell or rent your personal information to third parties for
          their marketing purposes without your explicit consent.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We share your personal information with third parties as under:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            We share personal information with service providers that provide us
            with services for the purpose of operating the App and opening and
            operating your account. Consistent with applicable legal
            requirements, we take commercially reasonable steps to require third
            parties to adequately safeguard your personal information and only
            process it in accordance with our instructions;
          </li>
          <li>
            We share personal information to law enforcement agencies, public
            authorities or other parties, if we believe we are required to do so
            by law, or that doing so is reasonably necessary to comply with our
            legal obligations;
          </li>
          <li>
            We share personal information with payment processors, fraud
            detection agencies and similar third parties for the purpose of
            facilitating payments done via the App and securing the App and
            protecting it against fraud or other liabilities;
          </li>
          <li>
            We also may share limited personal information with such social
            media platforms and marketing partners, such as Facebook, Twitter
            and Google, to promote our services. Such features and third party
            services collect certain information about you and may set a cookie
            to enable their functionality. Such third party features are
            governed by their privacy policies and under their terms and
            conditions.
          </li>
          <li>
            Personal information will also be disclosed if we go through a
            business transition such as a merger, sale, transfer of all or a
            portion of{" "}
            <a href="https://tappme.se/" target="_blank">
              TAPP ME
            </a>
            's assets, acquisition, bankruptcy or similar event. In the event
            that we sell any business or assets, we will disclose your data to
            the prospective buyer. If we or substantially all of our assets are
            acquired by a third party, information held by us about our users
            will be one of the transferred assets.
          </li>
        </ul>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Please note, when you publish your information and/or share your data
          with other Users, we try to maintain the standards of all users, but
          we do not take responsibility over the actions of other users.
        </p>
        <h3 className="mb-3 mt-1">
          <strong>Where We Store Your Personal Information</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We may store your data manually or electronically. Any data stored
          electronically will be stored in secure servers, and any data stored
          manually will be stored in our premises within the Germany.
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of data transmitted to
          us and any transmission is at your own risk.
        </p>

        <h3 className="mb-3 mt-3">
          <strong>Cookies</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We use cookies and similar technologies (such as web beacons, pixels,
          tags, and scripts) to improve and personalize your experience, provide
          our services, analyze performance and for marketing purposes.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          A cookie is a text file sent by a web server to a web browser and
          stored in the browser. The file is then sent back to the server each
          time the browser requests a page form the server. This enables the web
          server to identify and track the web browser.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We may send a cookie which may be stored by your browser on your
          computer’s hard drive. The information we obtain from the cookie could
          be used to improve the website’s usability and for marketing purposes.
          We may also use that information to recognize your computer when you
          use our App and to personalize our App for you.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Browsers usually will allow you to refuse cookies but this could have
          a negative impact on the usability of many websites.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          To learn more about how we and our third party service providers use
          cookies and your control over these Cookies, please see our Cookie
          Policy.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>Do Not Track</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "6rem",
          }}
        >
          Do Not Track (DNT) is a privacy preference that users can set in some
          web browsers, allowing users to opt out of tracking by the web and
          online services. We do not honor browser requests not to be tracked
          online (known as "Do Not Track"), but our Cookie Policy describes how
          you can opt out of receiving cookies. To learn more about cookies,
          including how to view which cookies have been set and how to manage
          and delete them, please visit:{" "}
          <a href="https://allaboutcookies.org" target="_blank">
            www.allaboutcookies.org
          </a>
          . At this time, our App do not respond to Do Not Track beacons sent by
          browser plugins.
        </p>
        <h3 className="mt-4 mb-3">Analytics:</h3>

        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We do not share our App traffic data with any third party for any
          purpose unless required for legal reasons such as meeting applicable
          laws, regulations, legal processes, or enforceable governmental
          requests and/or unless you have given an explicit consent to allow us
          to do so; enforcing applicable terms of service; detecting,
          preventing, or otherwise addressing fraud, security, or technical
          issues; protecting against harm to the rights, property or safety of
          our Company, our Customers, or the public as required or permitted by
          law.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          If you'd like to opt-out or If you'd like to obtain a copy of your
          data, make a correction, or have it erased, please contact us in any
          way convenient for you in the <strong>Contact Us</strong> section.
        </p>

        <h3 className="mt-4 mb-3">
          <strong>Marketing and Behavioral Remarketing</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "4rem",
          }}
        >
          We use marketing and remarketing services to advertise to you, or on
          third party websites to you after you visited our Service. We and our
          third-party vendors use cookies to inform, optimize and serve ads
          based on your past visits to our Service. To learn more about cookies,
          including how to view which cookies have been set and how to manage
          and delete them, please visit:{" "}
          <a href="https://allaboutcookies.org" target="_blank">
            www.allaboutcookies.org
          </a>
          .
        </p>

        <h3 className="mt-4 mb-3">GOOGLE ADS</h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We may use Google AdSense Advertising on our App. Google, as a
          third-party vendor, uses cookies to serve ads on our site. Google’s
          use of the DART cookie enables it to serve ads to our users based on
          previous visits to our site and other sites on the Internet. Users may
          opt-out of the use of the DART cookie by visiting the Google Ad and
          Content Network privacy policy. You may view their advertising and
          privacy policies at the following links:
        </p>
        <ul className="mb-4">
          <li>
            <a
              href="https://policies.google.com/technologies/ads"
              target="_blank"
            >
              https://policies.google.com/technologies/ads
            </a>
          </li>
          <li>
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
          </li>
        </ul>

        <h3 className="mt-3 mb-3">FACEBOOK</h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Facebook remarketing service is provided by Facebook Inc.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          You can learn more about interest-based advertising from Facebook by
          visiting this page:{" "}
          <a
            href="https://www.facebook.com/adpreferences/ad_settings"
            target="_blank"
          >
            https://www.facebook.com/adpreferences/ad_settings
          </a>
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          To opt-out from Facebook’s interest-based ads follow these
          instructions from Facebook:{" "}
          <a
            href="https://www.facebook.com/help/568137493302217"
            target="_blank"
          >
            https://www.facebook.com/help/568137493302217
          </a>
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Facebook adheres to the Self-Regulatory Principles for Online
          Behavioral Advertising established by the Digital Advertising
          Alliance. You can also opt-out from Facebook and other participating
          companies through the Digital Advertising Alliance in the USA{" "}
          <a href="https://www.aboutads.info/choices" target="_blank">
            https://www.aboutads.info/choices
          </a>
          , the Digital Advertising Alliance of Canada in Canada{" "}
          <a href="https://youradchoices.ca" target="_blank">
            https://youradchoices.ca{" "}
          </a>
          or the European Interactive Digital Advertising Alliance in Europe{" "}
          <a href="https://www.youronlinechoices.eu" target="_blank">
            https://www.youronlinechoices.eu
          </a>
          , or opt-out using your mobile device settings.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          For more information on the privacy practices of Facebook, please
          visit Facebook’s Data Policy:{" "}
          <a
            href="https://www.facebook.com/privacy/explanation"
            target="_blank"
          >
            https://www.facebook.com/privacy/explanation
          </a>
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          The full list of marketing and remarketing services that we use can be
          found below:
        </p>
        <ul className="ms-4">
          <li>
            <a href="https://policies.google.com/privacy" target="_blank">
              Google Ads
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/policy.php" target="_blank">
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://help.instagram.com/519522125107875"
              target="_blank"
            >
              Instagram
            </a>
          </li>
          <li>
            <a href="https://mailchimp.com/legal/privacy/" target="_blank">
              Mailchimp
            </a>
          </li>
        </ul>

        <h1 className="mt-5 mb-5">
          <strong>Disclaimers:</strong>
        </h1>

        <h3>Ads and External Links</h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Please note that the App may contains links to third party Apps and
          advertisements, and if you link to a third party App from the App, any
          data you provide to that App and any use of that data by the third
          party are not under the control of{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>{" "}
          and are not subject to this Policy. You should consult the privacy
          policies of each App you visit. This Policy applies solely to personal
          information collected by our App. If you upload content, including
          personal information, to a social network and then tag the App, your
          submission will be subject to that social network's terms of use and
          privacy policy, even where you post on an official{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>{" "}
          page on the social network. We do not have control over these terms of
          use and privacy policies and have not reviewed their adequacy. You
          should therefore review these before submitting any personal
          information.
        </p>

        <h3>Incorrect Contents</h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We take reasonable steps to ensure the accuracy of the information
          provide on the App and on the external link in this policy listed.
          However, the information is provided "as is" without any warranty of
          guarantee of any kind. Under no circumstance should you rely on the
          accuracy of information given on the App.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We take no responsibility or liability for the accuracy, content,
          completeness, reliability or legality of the information and contents
          contained on this App.
        </p>

        <h3 className="mt-3 mb-3">Paid Reviews</h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <a href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          may posts paid reviews and testimonials. We take steps to ensure some
          accuracy in the paid reviews, but under no circumstance to we take
          responsibility, accept liability, or ensure any accuracy in the
          reviews posted. You should, not in any case, rely on the reviews
          contained on the App.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>Security</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We maintain reasonable administrative, technical and physical
          safeguards in an effort to protect against unauthorized access, use,
          modification and disclosure of personal information in our custody and
          control, including only providing access to personal information to
          employees and authorized service providers who require such
          information for the purposes described in this Privacy Policy.
        </p>

        <h3 className="mt-3 mb-3">
          <strong>Rights of EU Users</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Under applicable EU regulation, in short, you have the following
          rights in respect of your personal information:
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          1) The right to be informed;
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          2) The right of access;
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          3) The right to erasure;
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          4) The right to rectification;
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          5) The right to restrict processing;
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          6) The right to data portability;
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          7) The right to object, and
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          8) Rights in relation to automated decision making and profiling.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          Under applicable EU regulation, in detail, you have the following
          rights in respect of your personal information:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            to obtain a copy of your personal information together with
            information about how and on what basis that personal information is
            processed;
          </li>
          <li>to rectify inaccurate personal information;</li>
          <li>
            to erase your personal information in limited circumstances where
            (a) you believe that it is no longer necessary for us to hold your
            personal information; (b) we are processing your personal
            information on the basis of legitimate interests and you object to
            such processing, and we cannot demonstrate an overriding legitimate
            ground for the processing; (c) where you have provided your personal
            information to us with your consent and you wish to withdraw your
            consent and there is no other ground under which we can process your
            personal information; and (d) where you believe the personal
            information we hold about you is being unlawfully processed by us;
          </li>
          <li>
            to restrict processing of your personal information where: (a) the
            accuracy of the personal information is contested; (b) the
            processing is unlawful but you object to the erasure of the personal
            information; (c) we no longer require the personal information for
            the purposes for which it was collected, but it is required for the
            establishment, exercise or defense of a legal claim or (d) you have
            objected to us processing your personal information based on our
            legitimate interests and we are considering your objection;
          </li>
          <li>
            to object to decisions which are based solely on automated
            processing or profiling;
          </li>
          <li>
            where you have provided your personal information to us with your
            consent, to ask us for a copy of this data in a structured,
            machine-readable format and to ask us to share (port) this data to
            another data controller; or
          </li>
          <li>
            to obtain a copy of or access to safeguards under which your
            personal information is transferred outside of the EEA.
          </li>
        </ul>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          In addition to the above, you have the right to lodge a complaint with
          a supervisory authority for data protection.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We will ask you for additional data to confirm your identity and for
          security purposes, before disclosing data requested by you. We reserve
          the right to charge a fee where permitted by law. We will decline to
          process requests that jeopardize the privacy of others, are extremely
          impractical, or would cause us to take any action that is not
          permissible under applicable laws. Additionally, as permitted by
          applicable laws, we will retain where necessary certain personal
          information for a limited period of time for record-keeping,
          accounting and fraud prevention purposes.
        </p>
        <p
          className="ms-4 mb-4"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          The owner and Data controller is{" "}
          <strong>
            <a href="https://tappme.se/" target="_blank">
              TAPP ME
            </a>{" "}
            MITAWAY AB office@mitaway.com
          </strong>
          .
        </p>
        <h3 className="mt-3 mb-3">
          <strong>Specific Provisions for California Residents</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          This section of the Policy applies to you, if you are a California
          resident.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We have collected the following categories of personal information
          from Users:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            Information that you chose to upload or otherwise provided by you
            which may include: (i) Identifiers and personal information, such as
            name, postal addresses, online identifiers, email addresses, and
            location.
          </li>
          <li>
            Information we collect when you use the App, including (i)
            Identifiers and personal information, such as online identifiers,
            internet protocol (IP) addresses, access device and connection
            information such as browser type, version, and time zone setting and
            browser plug-in types and versions; (ii) commercial information,
            including products or services purchased, obtained, or considered,
            or other purchasing or consuming histories or tendencies; (iii)
            Internet or other electronic network activity information,
            including, but not limited to log-in and log-out time, the duration
            of sessions, the content uploaded and downloaded, viewed web-pages
            or specific content on web-pages, activity measures; (iv) Location
            information, based on your IP address and GPS, (iv) phonebook based
            on your contact lists; (v) pictures based on your camera.
          </li>
          <li>
            Inferences drawn from any of the information identified above to
            create a profile about you.
          </li>
          <li>
            We obtain personal information listed above from third party
            sources, as detailed in this Policy.
          </li>
        </ul>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We use the personal information that we collect or receive for the
          business purposes as described above under the Section titled{" "}
          <strong>"How Do We Use the Information Collected?".</strong>
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We disclose personal information to third parties for business
          purposes as described above under the Section titled "Sharing Personal
          Information with Third Parties" in the Policy. In the preceding twelve
          (12) months, we have disclosed all the categories of personal
          information detailed above for business purposes.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          As previously mentioned in this Policy, we do not sell (as such term
          is defined in the CCPA) personal information and will not sell it
          without providing Users with a right to opt out.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          You are entitled to the following specific rights under the CCPA in
          relation to personal information related to you:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          <li>
            You have a right to request that we will disclose certain
            information to you about our collection and use of personal
            information related to you over the past 12 months. After verifying
            your request, we will disclose to you: (i) the categories of
            personal information that we collect about you; (ii) the categories
            of sources from which the personal information is collected; (iii)
            the purposes for collecting, using, or selling that personal
            information; (iv) the categories of personal information that we
            disclosed for a business purpose or sold, and the categories of
            third parties to whom we disclosed or sold that particular category
            of personal information; (v) the specific pieces of personal
            information that we have collected about you; (vi) if we disclosed
            your personal information for a business purpose, the categories of
            personal information we have disclosed; (vii) if we sold your
            personal information, the categories of personal information we have
            sold.
          </li>
          <li>
            You have a right to request that we delete personal information
            related to you that we collected from you under certain
            circumstances and exceptions.
          </li>
          <li>
            You also have a right not to be discriminated against for exercising
            your rights under the CCPA.
          </li>
        </ul>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          To make such requests, we kindly ask that you contact us at{" "}
          <strong>info@tappme.se</strong>.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We will verify your request using the information associated with your
          account, including email address. Government identification may also
          be required.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          A request for access can be made by you only twice within a 12-months
          period. Any disclosures that we provide will only cover the 12-months
          period preceding receipt of your request. We do not charge a fee to
          process or respond to your verifiable User request unless it is
          excessive, repetitive, or manifestly unfounded. If we determine that
          the request warrants a fee, we will inform you of the reasons for such
          decision and provide you with a cost estimate before processing
          further your request.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>Updating Personal Information</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          We take steps to ensure that the personal information we collect is
          accurate and up to date, and we provide you with the opportunity to
          update your information through your account profile settings. In the
          event that you believe your information is in any way incorrect or
          inaccurate, please let us know immediately. We will make sure we
          investigate the matter and correct any inaccuracies as quickly as
          possible where necessary or give you ways to update it quickly or to
          delete it - unless we have to keep that information for legitimate
          business or legal purposes. When updating your personal information,
          we will ask you to verify your identity before we can act on your
          request. If for any reason you have a problem with deleting your
          personal information, please contact us at{" "}
          <strong>info@tappme.se</strong> and we will make reasonable efforts to
          delete any such information pursuant to any applicable privacy laws.
        </p>

        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          You can review and change your personal information:{" "}
          <strong>Logging in to their personal page</strong>
        </p>

        <h3 className="mt-3 mb-3">
          <strong>Contact Us</strong>
        </h3>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          To exercise any of your rights in connection with your personal
          information, we kindly ask that you please contact us at
          <a
            className="ms-2 mb-3"
            style={{ color: "blue" }}
            href="mailto:info@tappme.se"
            target="_blank"
          >
            info@tappme.se
          </a>
          .
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          If you have any questions (or comments) concerning this Policy, please
          email our team at <strong> info@tappme.se</strong>, and we will make
          an effort to reply within a reasonable timeframe.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          For the purposes of GDPR (Article 27), you may contact our EU
          representative and Data Protection Officer:
          <a className="ms-2 mb-3" href="https://tappme.se/" target="_blank">
            TAPP ME{" "}
          </a>
          <strong>MITAWAY AB office@mitaway.com </strong>.
        </p>
        <p
          className="ms-4 mb-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "7rem",
            marginBottom: "2rem",
          }}
        >
          For any questions or queries regarding this Privacy Policy, please
          feel free to contact us:
        </p>
        <div>
          <a
            className="ms-4 mb-3"
            style={{ color: "blue" }}
            href="mailto:info@tappme.se"
            target="_blank"
          >
            info@tappme.se
          </a>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="logog p-4 pt-lg-5">
                    <img src="assets/img/tappme-logo.png" />
                    <p class="mt-4 mb-3 text-color">
                      Try TAPPME Startup/ Professional Plans
                    </p>
                    <h3 class="fw-normal mt-4">15 days Free</h3>
                    <p class="mt-3 mb-3 text-color">
                      After free trial $475/year will be charged
                    </p>
                    <div class="border py-4 px-3">
                      <p class="d-flex justify-content-between align-items-center text-dark mb-3">
                        TAPPME Startup/Professional Plans
                        <span class=" text-dark">14 days free</span>
                      </p>
                      <p class="d-flex justify-content-between align-items-center text-black-50">
                        Quantity: 10{" "}
                        <span class="text-black-50">$475/year</span>
                      </p>
                      <hr />
                      <p class="d-flex justify-content-between align-items-center text-gray mb-3">
                        Subtotal <span class="">$475</span>
                      </p>

                      <p class="d-flex justify-content-between align-items-center text-primary mt-3">
                        Add promotion code{" "}
                        <span class="w-50">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{
                    boxShadow: "0px 4px 22.2px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div class="logog p-4 px-lg-5 pt-lg-5">
                    <h3 class="fw-normal">Enter payment details</h3>

                    <div class="mb-3 mt-5">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicies;
