import http from "../baseURL/httpcommon";

const getBlogs = () => {
  return http.get(`/blog/all`);
};

const getBlog = (slug) => {
  return http.get(`/blog/${slug}`);
};

const getPopularBlog = () => {
  return http.get(`/blog/top-views`);
};

const getRecentBlogs = () => {
  return http.get(`/blog/recent`);
};
const BlogServices = {
  getBlogs,
  getBlog,
  getPopularBlog,
  getRecentBlogs,
};

export default BlogServices;
