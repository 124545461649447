import http from "../baseURL/httpcommon";
import authHeader from "../baseURL/authHeader";

const getNotification = (workforceId) => {
  return http.get(`/notification/worker/${workforceId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const findNotificationById = (id) => {
  return http.get(`/notification/${id}`);
};

const NotificationServices = {
  getNotification,
  findNotificationById,
};

export default NotificationServices;
