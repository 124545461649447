import React from "react";
import Footer from "./footer";
import Header from "./header";
const AboutUs = () => {
  return (
    <>
      <Header />
      <section id="about" class="about-area ptb-100">
        <div class="container">
          <div class="section-title">
            <h2>About Our App</h2>
            <div class="bar"></div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
              suspendisse ultrices gravida.
            </p>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <h3>Our process is simple, Our App is powerful</h3>

                <p class="text-justify">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </p>
                <p class="text-justify">
                  {" "}
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text. All the Lorem Ipsum generators on the Internet tend
                  to repeat predefined chunks as necessary, making this the
                  first true generator on the Internet.
                </p>

                <div class="about-btn">
                  <a href="/" class="default-btn">
                    Download Now
                    <span></span>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-image">
                <img src="assets/img/tapme-mobile.png" alt="image" />
              </div>
            </div>
          </div>
        </div>

        <div class="default-shape">
          <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
          </div>

          <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
          </div>

          <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
          </div>

          <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
          </div>

          <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
          </div>
        </div>
      </section>

      <section id="features" class="features-area pb-70">
        <div class="container">
          <div class="section-title">
            <h2>Awsome Features</h2>
            <div class="bar"></div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
              suspendisse ultrices gravida.
            </p>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-sun"></i>
                </div>
                <h3>High Resolution</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-lightbulb"></i>
                </div>
                <h3>Unique Design</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-desktop"></i>
                </div>
                <h3>Full Responsive</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-code"></i>
                </div>
                <h3>Clean Codes</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-eye"></i>
                </div>
                <h3>Retina Ready</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-camera"></i>
                </div>
                <h3>Unlimited Features</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="default-shape">
          <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
          </div>

          <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
          </div>

          <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
          </div>

          <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
          </div>

          <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="logog p-4 pt-lg-5">
                    <img src="assets/img/tappme-logo.png" />
                    <p class="mt-4 mb-3 text-color">
                      Try TAPPME Startup/ Professional Plans
                    </p>
                    <h4 class="fw-normal mt-4">15 days Free</h4>
                    <p class="mt-3 mb-3 text-color">
                      After free trial $475/year will be charged
                    </p>
                    <div class="border py-4 px-3">
                      <p class="d-flex justify-content-between align-items-center text-dark mb-3">
                        TAPPME Startup/Professional Plans
                        <span class=" text-dark">14 days free</span>
                      </p>
                      <p class="d-flex justify-content-between align-items-center text-black-50">
                        Quantity: 10{" "}
                        <span class="text-black-50">$475/year</span>
                      </p>
                      <hr />
                      <p class="d-flex justify-content-between align-items-center text-gray mb-3">
                        Subtotal <span class="">$475</span>
                      </p>

                      <p class="d-flex justify-content-between align-items-center text-primary mt-3">
                        Add promotion code{" "}
                        <span class="w-50">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{
                    boxShadow: "0px 4px 22.2px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div class="logog p-4 px-lg-5 pt-lg-5">
                    <h4 class="fw-normal">Enter payment details</h4>

                    <div class="mb-3 mt-5">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
