import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

//get all chats
const getUsers = (companyId) => {
  console.log("companyId" + companyId);
  return http.get(`/chat/company/${companyId}/users-with-messages`, {
    headers: {
      ...authHeader(),
    },
  });
};
const uploadImages = (formData) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return http.post("/chat/image/upload", formData, { headers: headers });
};

const ChatServices = {
  getUsers,
  uploadImages,
};

export default ChatServices;
