import http from "../baseURL/httpcommon";

const getTutorials = () => {
  return http.get(`/tutorials/all`);
};

const getTutorial = (slug) => {
  return http.get(`/tutorials/${slug}`);
};

const getPopularTutorials = () => {
  return http.get(`/tutorials/top-views`);
};

const getRecentTutorials = () => {
  return http.get(`/tutorials/recent`);
};
const TutorialServices = {
  getTutorials,
  getTutorial,
  getPopularTutorials,
  getRecentTutorials,
};

export default TutorialServices;
