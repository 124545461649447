import React from "react";
import Footer from "./footer";
import Header from "./header";

const CookiesPolicy = () => {
  return (
    <>
      <Header />
      <div className="container pt-3 pb-4 ">
        <h1>COOKIE POLICY</h1>
        <p className="ms-3 mb-4">Last updated May 12, 2024</p>

        <h3 className="mt-3 mb-2">
          <strong>INTRODUCTION</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          <strong>
            <a href="https://tappme.se/" target="_blank">
              TAPP ME
            </a>
          </strong>{" "}
          (hereinafter, "we", "us", "our", "Site" or the "App") may use cookies,
          and other tracking technologies upon visiting or using its App, to
          help customize the App and improve the consumer experience.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          We reserve the right to make changes to this Cookie Policy at any time
          and for any reason. We will alert visitors about any changes by
          updating the "Last Updated" date of this Cookie Policy. Any changes or
          modifications will be effective immediately upon posting the updated
          Cookie Policy on the App or Site, and the right to receive specific
          notice of each such change or modification is waived.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          Visitors are encouraged to review this Cookie Policy periodically in
          order to be aware of updates. Visitors will be deemed to have been
          made aware of, will be subject to, and will be deemed to have accepted
          the changes in any revised Cookie Policy by the continued use of the
          App after the date such revised Cookie Policy is posted.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>What is a cookie?</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          A cookie is a piece of information in the form of a very small text
          file that is placed on an internet user's hard drive. It is generated
          by a web page server, which is basically the computer that operates a
          web site. The information the cookie contains is set by the server and
          it can be used by that server whenever the user visits the site. A
          cookie can be thought of as an internet user's identification card,
          which tell a web site when the user has returned.
        </p>

        <h3 className="mt-3 mb-3">
          <strong>HOW ARE COOKIES USED FOR ADVERTISING PURPOSES?</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          Cookies and ad technology such as web beacons, pixels, and anonymous
          ad network tags help us serve relevant ads to you more effectively.
          They also help us collect aggregated audit data, research, and
          performance reporting for advertisers. Pixels enable us to understand
          and improve the delivery of ads to you and know when certain ads have
          been shown to you. Since your web browser may request advertisements
          and web beacons directly from ad network servers, these networks can
          view, edit, or set their own cookies, just as if you had requested a
          web page from their site.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          Although we do not use cookies to create a profile of your browsing
          behavior on third-party sites, we do use aggregate data from third
          parties to show you relevant, interest-based advertising. We do not
          provide any personal information that we collect to advertisers. You
          can opt out of off-site and third party-informed advertising by
          adjusting your cookie settings. Opting out will not remove advertising
          from the pages you visit, but, instead, opting out will result in the
          ads you see not being matched to your interests. This implies that the
          ad(s) you see will not be matched to your interests by those specific
          cookies.
        </p>

        <h3 className="mt-3 mb-3">
          <strong>CONTROL OF COOKIES</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          Cookies are accepted by default on most browsers, but this may be
          changed from the browser’s settings. You can change your preferences
          for our Website and App and/or the websites of any third-party
          suppliers by changing your browser settings. Please note that most
          browsers automatically accept cookies. Therefore, if you do not wish
          cookies to be used, you may need to actively delete or block the
          cookies. However, please be aware that such action could affect the
          availability and functionality of the Website or App.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          For more information on how to control cookies, please check the
          browser or device’s settings for how cookies may be controlled or
          rejected.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>OTHER TRACKING TECHNOLOGIES</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          In addition to cookies, we may use web beacons, pixel tags, and other
          tracking technologies on the Site and App to help customize the Site
          and improve your experience. A "web beacon" or "pixel tag" is tiny
          object or image embedded in a web page or email. They are used to
          track the number of users who have visited particular pages and viewed
          emails and acquire other statistical data. They collect only a limited
          set of data, such as a cookie number, time and date of page or email
          view, and a description of the page or email on which they reside. Web
          beacons and pixel tags cannot be declined. However, you can limit
          their use by controlling the cookies that interact with them.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>PRIVACY POLICY</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          For more information about how we use information collected by cookies
          and other tracking technologies, please refer to our Privacy Policy
          posted on the Site. This Cookie Policy is part of and is incorporated
          into our Privacy Policy. By using the Site, you agree to be bound by
          this Cookie Policy and our Privacy Policy.
        </p>
        <h3 className="mt-3 mb-3">
          <strong>CONTACT US</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          For any questions or queries regarding this Cookie Policy, please feel
          free to contact us:
        </p>
        <div className="ms-3">
          <strong>
            <a
              href="mailto:info@tappme.se"
              style={{ color: "blue" }}
              target="_blank"
            >
              info@tappme.se
            </a>
          </strong>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CookiesPolicy;
