import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import NotificaionServices from "./services";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { formatDateWithTime } from "../../../../helper/DateFormater";

const CompanyNotification = () => {
  const [notification, setNotification] = useState([]);
  const [open, setOpen] = useState();
  const [severity, setSeverity] = useState();
  const [message, setMessage] = useState();
  const [showModal, setShowModal] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(0);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  const workforceId = jwt?.workforceId;

  const getNotification = () => {
    debugger;
    NotificaionServices.getNotification(workforceId)
      .then((response) => {
        const data = response.data.data.notificationList;
        setNotification(data);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            console.log("Notification not found:", error.data);
            // setMessage(error.response.message);
            setSeverity("error");
            setOpen(true);
          } else if (
            error.response.status === 500 ||
            error.response.status === 400 ||
            error.reponse.status === 504
          ) {
            // setMessage(error.message);
            setSeverity("error");
            setOpen(true);
          } else {
            console.log("Other error:", error.response.data);
            // setMessage(error.response.message);
            setSeverity("error");
            setOpen(true);
          }
        } else {
          console.log("Error setting up request:", error.message);
          // setMessage(error.response.message);
          setSeverity("error");
          setOpen(true);
        }
      });
  };

  useEffect(() => {
    getNotification();
  }, []);

  const handleClose = () => {
    debugger;
    setShowModal(false);
    setOpen(false);
  };
  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="row g-3 align-items-center">
          <div className="col-4 col-md-2 col-lg-2">
            <h5 className="mb-5">{t("Notification")}</h5>
          </div>
          <div className="col-8 col-md-10 col-lg-10">
            <div className="text-right">
              {/* <button
                  className="btn btn-outline-secondary dropdown-toggle btn-blue px-3 mb-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("All")}
                </button>
                <ul className="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a className="dropdown-item" href="#">
                      {t("Approved")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {t("Rejected")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {t("Pending")}
                    </a>
                  </li>
                </ul> */}
            </div>
          </div>
        </div>
        {notification && notification.length > 0 ? (
          notification.map((item) => (
            <div key={item.id}>
              <div className="card radius-15">
                <div className="card-body card-border align-items-center">
                  <p className="card-text pt-2">
                    {/* {console.log("notification_123", notification)} */}
                    <b>{item.description.slice(0, -1)}</b> on
                    <span className="text-primary ms-1">
                      {formatDateWithTime(item.timestamp)}
                      {/* {item.type} */}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="ms-3">No notification Found</p>
        )}
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={1000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanyNotification;
