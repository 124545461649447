import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getSubscriptionData = () => {
  return http.get(`company/worker/sharecount`, {
    headers: {
      ...authHeader(),
    },
  });
};
const getInvoiceData = (page, pageSize, userId) => {
  debugger;
  return http.get(
    `payment/company?userId=${userId}&pageNumber=${page}&pageSize=${pageSize}&sortBy=createdAt&sortDir=desc`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const getCompanyCurrentSubscription = (userId) => {
  return http.get(`/user/user/current/sub/${userId}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const SubscriptionServices = {
  getSubscriptionData,
  getInvoiceData,
  getCompanyCurrentSubscription,
};

export default SubscriptionServices;
