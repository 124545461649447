// import React from "react";
// import { ReactPhotoCollage } from "react-photo-collage";

// const ImageCollage = ({ images }) => {
//   if (!images || images.length === 0) {
//     return null;
//   }

//   const numImages = images?.length;

//   // Calculate the number of rows and columns for the layout
//   let numRows = 1;
//   let numCols = numImages;
//   for (let i = 1; i * i <= numImages; i++) {
//     if (numImages % i === 0) {
//       numRows = i;
//       numCols = numImages / i;
//     }
//   }

//   const layout = Array(numRows).fill(numCols);

//   const collageSettings = {
//     width: "60%",
//     height: ["120px", "120px"],
//     layout: [1, layout],
//     photos: images.map((image) => ({ source: image.imageUrl })),
//     showNumOfRemainingPhotos: true,
//   };

//   return <ReactPhotoCollage {...collageSettings} />;
// };

// export default ImageCollage;

import React from "react";
import { ReactPhotoCollage } from "react-photo-collage";

const ImageCollage = ({ images }) => {
  if (!images || images.length === 0) {
    return null;
  }

  const numImages = images.length;
  let layout;

  // Determine layout based on the number of images
  if (numImages === 1) {
    layout = [1];
  } else if (numImages === 2) {
    layout = [1, 1];
  } else if (numImages === 3) {
    layout = [1, 2];
  } else {
    layout = [1, 2]; // Adjust this to create a layout with the correct number of columns
  }

  const collageSettings = {
    width: "40%",
    height: ["120px", "120px"],
    layout: layout,
    photos: images.map((image) => ({ source: image.imageUrl })),
    showNumOfRemainingPhotos: true,
  };

  return <ReactPhotoCollage {...collageSettings} />;
};

export default ImageCollage;
