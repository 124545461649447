import http from "../baseURL/httpcommon";

//get all reports
const findChatMessage = (id) => {
  return http.get("messages/" + id);
};

const findChatMessages = (senderId, recipientId) => {
  return http.get("messages/" + senderId + "/" + recipientId);
};

const countNewMessages = (senderId, recipientId) => {
  return http.get("/messages/" + senderId + "/" + recipientId + "/count");
};
const MessageServices = {
  findChatMessage,
  findChatMessages,
  countNewMessages,
};

export default MessageServices;
