import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getBillingData = (workerId) => {
  return http.get(`billing/all/${workerId}`);
};
const BillingServices = {
  getBillingData,
};

export default BillingServices;
