import { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(
  "pk_test_51OWMS8HuEZgadR502l9pQEld0VFMjnlugUlkGlTSS1DOxGIljfLHx0cXhcZGd0nUwNYdnf7vlpXG52UIPghBT6xd004RvYUQ7z"
);
const Payment = () => {
  const [validated, setValidated] = useState(false);
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pi_3OmBfJHuEZgadR500gFb1mMR_secret_sPnm43tJ5ZOMK5mu6C0HKSrB3",
  };

  return (
    <>
      <div className="wrapper">
        <main className="authentication-content">
          <div className="container">
            <div className="authentication-card">
              <div className="card shadow rounded-0 overflow-hidden w-100  mt-3">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login align-items-center justify-content-center">
                    <div className="px-4 px-lg-5 text-center">
                      <img
                        className="me-2 mb-3 mt-lg-5 pt-lg-5"
                        src="assets/images/login-img.png"
                        alt="login-img"
                      />
                      <p className="mt-lg-5">
                        Your all-in-one solution designed to streamline
                        registration processes, enhance workflow visibility, and
                        drive productivity across a wide range of industries
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                      <div className="text-center">
                        <img
                          className="me-2 mb-3"
                          src="assets/images/tapme-icon.svg"
                          width="66"
                          alt=""
                        />
                        <h3 className="card-title mb-3">
                          Log in to your account
                        </h3>
                        <p className="text-blue font-14 mb-4">
                          Login with credentials shared by
                          <br />
                          your organization.
                        </p>
                      </div>

                      <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm />
                      </Elements>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Payment;
