import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const SideNav = () => {
  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  //const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt?.role[0]?.name;
  const menuSetting = useRef(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!menuSetting.current) {
      var script = document.createElement("script");
      var menuCode = `$(document).ready(function(){
        $("#menu").metisMenu()
      });`;
      script.innerHTML = menuCode;
      document.body.appendChild(script);
      menuSetting.current = true;
    }
  }, []);

  const companyMenu = [
    {
      path: "/dashboard/company/home",
      title: t("Home"),
      icon: "bi bi-house-door",
    },
    {
      path: "/dashboard/company/sites",
      title: t("Sites"),
      icon: "fadeIn animated bx bx-buildings",
    },

    {
      path: "/dashboard/company/workforce",
      title: t("Workforce"),
      icon: "lni lni-users",
    },
    {
      path: "/dashboard/company/wokers",
      title: t("Workers"),
      icon: "fadeIn animated bx bx-user-plus",
    },
    {
      path: "/dashboard/company/billing",
      title: "Billing and Payment",
      icon: "fadeIn animated bx bx-credit-card",
    },
    // {
    //   path: "/",
    //   title: "NFC Cards",
    //   icon: "fadeIn animated bx bx-mobile",
    // },
    // {
    //   path: "/dashboard/company/reports",
    //   title: "Reports",
    //   icon: "fadeIn animated bx bx-spreadsheet",
    // },

    {
      path: "/dashboard/company/subscriptions",
      title: "Subscription",
      icon: "fadeIn animated bx bx-file-blank",
    },
    {
      path: "/dashboard/company/messages",
      title: "Messages",
      icon: "fadeIn animated bx bx-message-square-detail",
    },
    {
      path: "/dashboard/company/requests",
      title: t("Requests"),
      icon: "fadeIn animated bx bx-bell",
    },

    // {
    //   path: "/dashboard/company/notification",
    //   title: t("Notification"),
    //   icon: "fadeIn animated bx bx-notification",
    // },
    {
      path: "/dashboard/company/settings",
      title: t("Settings"),
      icon: "lni lni-cog",
    },
    {
      path: "/dashboard/company/myProfile",
      title: t("My Profile"),
      icon: "fadeIn animated bx bx-user-circle",
    },
    // {
    //   path: "/",
    //   title: "Logout",
    //   icon: "fadeIn animated bx bx-log-out-circle",
    // },
  ];

  return (
    <>
      {jwt !== null ? (
        <aside className="sidebar-wrapper" data-simplebar="true">
          <div className="sidebar-header">
            <div>
              <img
                src="/assets/images/brand-logo-2.png"
                className="logo-icon"
                alt="logo icon"
              />
            </div>
          </div>

          <ul className="metismenu" id="menu">
            {role === "ROLE_ADMIN"
              ? companyMenu.map((item, i) => (
                  <li key={i}>
                    <NavLink to={item.path}>
                      <div className="parent-icon">
                        <i className={item.icon}></i>
                      </div>
                      <div className="menu-title">{item.title}</div>
                    </NavLink>
                  </li>
                ))
              : null}
          </ul>
        </aside>
      ) : null}
    </>
  );
};

export default SideNav;
