import http from '../../../../baseURL/httpcommon'
import authHeader from '../../../../baseURL/authHeader'
import { jwtDecode } from 'jwt-decode';

const getMyProfile = (id) => {
    console.log("jwt.id", id)
    return http.get(`/user/get/${id}`, {
        headers: {
            ...authHeader(),
        }
    })
}

const uploadProfile = (formData) => {
    debugger;
    return http.put(`user/profile/photo`, formData, {
        headers: {
            ...authHeader(),
        },
    });
};
const updateProfile = (datas) => {
    return http.put(`company/update`, datas, {
        headers: {
            ...authHeader(),
        }
    })
}
const myProfileApi = {
    getMyProfile,
    uploadProfile,
    updateProfile
}

export default myProfileApi