import {
  CardElement,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import React from "react";
import { useNavigate } from "react-router-dom";

class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    const navigate = useNavigate();
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props;
    console.log("Stripe_element", stripe, elements);

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    stripe.createToken(CardElement).then(function (result) {
      // Handle result.error or result.token
      console.log("create_token_element", result);
    });

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/success",
      },
    });

    console.log("after_api_hit_result", result);
    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Check if PaymentIntent status is "succeeded"
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        // Redirect user to success page
        navigate("/success");
      } else {
        // Redirect user to cancel page indicating payment failed
        navigate("/cancel");
      }
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <PaymentElement />
        <button className="mt-4" disabled={!this.props.stripe}>
          Submit
        </button>
      </form>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
