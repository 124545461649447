/*global google*/
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./style.css";
import { Button, ButtonGroup } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

const GoogleMap = (prop) => {
  console.log("prop", prop);
  const [address, setAddress] = useState("");
  const [dlatitude, setDlatitude] = useState("");
  const [dlongitude, setDlongitude] = useState("");
  const [polygonVertices, setPolygonVertices] = useState([]);
  const [zoomLevel, setZoomLevel] = useState("18");
  // const schemaData = {
  //     siteName: "",
  //     boundaryAddress: "",
  //     centerLatitude: "",
  //     centerLongitude: "",
  //     radius: "",
  //     polygonVertices: [],
  // }
  // const [data, setData] = useState(schemaData)

  useEffect(() => {
    console.log("test LatLong", dlatitude, dlongitude, polygonVertices);
    prop.propValue(dlatitude, dlongitude, polygonVertices, zoomLevel);
  }, [dlatitude, dlongitude, polygonVertices, zoomLevel]);
  // Javascript Map Call strat
  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current === false) {
      const script = document.createElement("script");

      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCdAoXQ2RWPZZF71oywO6Zfc0RwXdPN57A&callback=initAutocomplete&libraries=drawing,places&v=weekly";
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
        effectRan.current = true;
      };
    }
  }, []);

  function initAutocomplete() {
    let drawingManager;
    let shapes = [];
    let undoneShapes = [];
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        console.log("Geo-location is not supported by this browser.");
      }
    }

    function showPosition(position) {
      var plat = position.coords.latitude;
      var plng = position.coords.longitude;
      map.setCenter(new google.maps.LatLng(plat, plng));
      setDlatitude(plat);
      setDlongitude(plng);
      // prop.propValue(plat, plng, "")
      console.log("test LatLong", plat, plng, "");
      // new google.maps.Marker({ position: { lat: plat, lng: plng }, map, title: "Your Current Location", });
    }

    getLocation();

    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 18,
      mapTypeId: "roadmap",
    });
    var currentZoomLevel = map.getZoom();
    console.log("Current Zoom Level:", currentZoomLevel);
    setZoomLevel(currentZoomLevel);
    google.maps.event.addListener(map, "zoom_changed", function () {
      var currentZoomLevel = map.getZoom();
      console.log("Current Zoom Level:", currentZoomLevel);
      setZoomLevel(currentZoomLevel);
      // You can do whatever you want with the zoom level here
    });
    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);

    // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }
      var addre = places[0].formatted_address;
      console.log(places[0].formatted_address);
      setAddress(addre);
      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        console.log(
          "test LatLong",
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
        setDlatitude(place.geometry.location.lat());
        setDlongitude(place.geometry.location.lng());
        //prop.propValue(place.geometry.location.lat(), place.geometry.location.lng(), "")
        // console.log("place.geometry.location", place.geometry.location.lat(), place.geometry.location.lng())
        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map,
            //icon,
            title: place.name,
            position: place.geometry.location,
            draggable: true,
          })
        );
        console.log(markers[0]);

        google.maps.event.addListener(markers[0], "dragend", function (a) {
          console.log(a);
          setDlatitude(a.latLng.lat());
          setDlongitude(a.latLng.lng());
        });

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      map.fitBounds(bounds);
      let infoWindow = new google.maps.InfoWindow({});

      infoWindow.open(map);
    });

    map.addListener("click", (mapsMouseEvent) => {
      console.log(mapsMouseEvent.latLng.toJSON());
    });
    function initializeDrawingManager() {
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            // google.maps.drawing.OverlayType.MARKER,
            // google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            // google.maps.drawing.OverlayType.POLYLINE,
            // google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },
        circleOptions: {
          fillColor: "#ffff00",
          fillOpacity: 1,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      drawingManager.setMap(map);
      google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function (event) {
          shapes.push(event.overlay);
          console.log(shapesToJSON());
        }
      );
    }
    initializeDrawingManager();
    function undo() {
      if (shapes.length > 0) {
        const lastShape = shapes.pop();
        lastShape.setMap(null);
        undoneShapes.push(lastShape);
        console.log(shapesToJSON());
      }
    }

    function redo() {
      if (undoneShapes.length > 0) {
        const lastUndoneShape = undoneShapes.pop();
        lastUndoneShape.setMap(map);
        shapes.push(lastUndoneShape);
        console.log(shapesToJSON());
      }
    }

    // Function to convert array of shapes to JSON
    function shapesToJSON() {
      const shapesJSON = shapes.map(function (shape) {
        if (shape instanceof google.maps.Marker) {
          return {
            type: "marker",
            position: {
              lat: shape.getPosition().lat(),
              lng: shape.getPosition().lng(),
            },
          };
        } else if (shape instanceof google.maps.Circle) {
          return {
            type: "circle",
            center: {
              lat: shape.getCenter().lat(),
              lng: shape.getCenter().lng(),
            },
            radius: shape.getRadius(),
          };
        } else if (
          shape instanceof google.maps.Polygon ||
          shape instanceof google.maps.Polyline
        ) {
          const path = shape
            .getPath()
            .getArray()
            .map(function (latLng) {
              return { lat: latLng.lat(), lng: latLng.lng() };
            });

          //  const pathObj = path.flatMap(item => Object.values(item))
          var obj = [];
          for (let key in path) {
            obj.push(path[key]);
          }
          //schemaData.polygonVertices = obj
          setPolygonVertices(obj);
        } else if (shape instanceof google.maps.Rectangle) {
          return {
            type: "rectangle",
            bounds: {
              north: shape.getBounds().getNorthEast().lat(),
              south: shape.getBounds().getSouthWest().lat(),
              east: shape.getBounds().getNorthEast().lng(),
              west: shape.getBounds().getSouthWest().lng(),
            },
          };
        }
      });
      return JSON.stringify(shapesJSON);
    }

    document.getElementById("undo").addEventListener("click", undo);
    document.getElementById("redo").addEventListener("click", redo);
  }

  window.initAutocomplete = initAutocomplete;
  return (
    <>
      {/* <button onClick={() => console.log("setPolygonVertices", JSON.stringify(polygonVertices))}>test</button> */}
      <Row>
        <Form.Group as={Col} md="9" controlId="validationCustom01">
          <Form.Control
            id="pac-input"
            type="text"
            placeholder="Search Location "
            required
            onChange={(e) => setAddress(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Provide valid address .
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          as={Col}
          md="3"
          controlId="validationCustom01"
          style={{ paddingLeft: "0" }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            sx={{ width: "100%" }}
          >
            <Button id="undo" sx={{ width: "100%" }}>
              <UndoIcon />
            </Button>
            <Button id="redo" sx={{ width: "100%" }}>
              <RedoIcon />
            </Button>
          </ButtonGroup>
        </Form.Group>
        <Col>
          <div
            id="map"
            style={{
              width: "100%",
              height: "600px",
              marginTop: "15px",
              boxShadow: "0px 0px 5px #ddd",
            }}
          ></div>
        </Col>
      </Row>
    </>
  );
};
export default GoogleMap;
