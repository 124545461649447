import React from "react";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="logo">
            <a href="/">
              <img src="/assets/img/tappme-logo.png" alt="Tapp Me" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a href="/" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="/aboutus" className="nav-link">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <HashLink smooth to="/#plan">
                  Subscription
                </HashLink>
              </li>
              <li className="nav-item">
                <a href="/blogs" className="nav-link">
                  Blogs
                </a>
              </li>
            </ul>
            <div className="others-option">
              <div className="d-flex align-items-center">
                <HashLink to="/login" className="pt-2 pe-4 login-link">
                  Sign in
                </HashLink>
                <div className="option-item">
                  <HashLink smooth to="/#plan" className="default-btn">
                    Start Free Trial
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
